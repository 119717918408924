.CreateForm {
    overflow: hidden;
}

form.CreateClusterNode-form {
    width: 100%;
    padding: 0px 0px 0px 8px;

    .ClusterNodeHeadingDiv {
        font-weight: 700;
        font-size: 14px;
        color: #0078d4;
    }

    .ClusterNodemainDiv {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .buttonDiv {
            display: flex;
            grid-gap: 10px;
            gap: 10px;
            padding: 16px 125px;

            justify-content: center;
            align-items: center;

            @media screen and (min-width:320px) and (max-width:425px) {
                padding: 13px 0px 0px 0px;
            }

            @media screen and (min-width:426px) and (max-width:768px) {
                padding: 16px 0px 9px 0px;
            }

            button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.saveBtn {
                background-color: #0078d4;

                @media screen and (min-width:320px) and (max-width:425px) {
                    height: 4vh;
                    width: 24vw;
                    font-size: 13px;
                }
            }

            .saveBtn {
                span.MuiButton-label {
                    font-weight: 700;
                    color: white;
                }
            }

            .btn-primary {
                @media screen and (min-width:320px) and (max-width:425px) {
                    height: 4vh;
                    width: 24vw;
                    font-size: 13px;
                }

                span.MuiButton-label {
                    font-weight: 700;
                    color: blackF;

                }
            }


        }

        .nodeIdIv {
            display: flex;
            align-items: center;
            gap: 16px;
            margin-top: 5px;
            justify-content: space-between;

            @media screen and (min-width:426px) and (max-width:768px) {
                gap: 3px;
            }

            @media screen and (min-width:320px) and (max-width:425px) {
                gap: 3px;
            }

            .nodeIdHeading {
                font-weight: 720;
                font-size: 12px;
                color: #000;

                @media screen and (min-width:320px) and (max-width:425px) {
                    font-size: 11px;
                    max-width: 31%;
                }

                @media screen and (min-width:426px) and (max-width:768px) {
                    font-size: 13px;
                    max-width: 31%;
                }
            }

            .nodeIdinput.select {
                .MuiInputBase-root.MuiInput-root.MuiInput-underline {
                    width: 100%;
                    border: 1.5px solid #ccc;
                    border-radius: 5px;
                    padding: 2.5px;
                }

                .MuiInput-underline:before {
                    border-bottom: 0px !important;
                }
            }

            .nodeIdinput {
                margin: 5px 0px;
                width: 72%;

                @media screen and (min-width:426px) and (max-width:768px) {
                    .MuiOutlinedInput-inputMarginDense {
                        padding-top: 9.5px;
                        padding-bottom: 9.5px;
                        font-size: 13px;
                    }

                    div#demo-select-small {
                        padding: 4px;
                    }
                }

                @media screen and (min-width:320px) and (max-width:425px) {
                    width: 62%;

                    .MuiOutlinedInput-inputMarginDense {
                        padding-top: 7.5px;
                        padding-bottom: 7.5px;
                        font-size: 12px;
                    }

                    div#demo-select-small {
                        padding: 4px;
                    }
                }


                .MuiFormControl-root.MuiTextField-root {
                    width: 100%;

                }

                .NodeInputWidth {
                    width: 100%;
                    padding: 4px;

                }


            }


        }


    }


}

.textDiv {
    font-size: 12.5px;
    text-align: end;
    margin-top: 5px;
}


.viewCredential {
    overflow-x: hidden;

    .CreateClusterNode-form {
        width: 100%;
        padding: 0px 0px 0px 8px;

        .ClusterNodeHeadingDiv {
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #0078d4;

        }

        .ClusterNodemainDiv {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .buttonDiv {
                display: flex;
                gap: 8px;
                padding: 20px 0px 0px 0px;
                justify-content: flex-start;
                align-items: flex-start;
                float: right;

                .CloseBtn {
                    float: right;
                }

                button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.saveBtn {
                    background-color: #0078d4;

                }


                .btn-primary {
                    span.MuiButton-label {
                        font-weight: 700;
                    }

                    border-radius: 3px;
                    background-color: #707070;
                }

                .EditBtn {
                    .btn-primary {
                        span.MuiButton-label {
                            font-weight: 700;
                        }

                        border-radius: 5px;
                        background-color: #0078d4;

                        color: #fff;
                    }
                }


            }

            .nodeIdIv {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 2px;

                .nodeIdHeading {
                    font-weight: 700 !important;
                    font-size: 12px;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.25;
                    letter-spacing: 0.2px;
                    color: #000;
                }

                .nodeIdinput.select {
                    .MuiInputBase-root.MuiInput-root.MuiInput-underline {
                        width: 100%;
                        border: 1.5px solid #ccc;
                        border-radius: 5px;
                        padding: 2.5px;
                    }

                    .MuiInput-underline:before {
                        border-bottom: 0px !important;
                    }
                }

                .nodeIdinput {
                    margin: 5px 0px;
                    width: 75%;
                    font-size: 12px;

                    .MuiFormControl-root.MuiTextField-root {
                        width: 100%;

                    }

                    .NodeInputWidth {
                        width: 100%;
                        padding: 4px;

                    }


                }


            }


        }


    }
}

.clusterCompare {

    .view-wrapper {
        &.view-wrapper-cluster-details {
            position: relative;
            display: flex;
            padding-top: var(--content-padding);
            padding-bottom: var(--content-padding);
            background-color: var(--main-background-color) !important;

            @media screen and (max-width :550px) {
                padding-left: 15px;
                padding-right: 15px;
              }
              
            .main-header {
                .main-title {
                    .title {
                        @media screen and (min-width : 1900px) {
                            font-size: 25px;
                        }
                    }
                }
            }
        }
    }

    .subheader-filter {
        flex-wrap: unset;

        @media screen and (min-width : 550px ) and (max-width:1070px) {
            margin-top: 10px;
            width: 100%;
        }

        @media screen and (max-width : 768px) {
            flex-wrap: wrap;
        }

        .search-header {
            margin-left: 0px;
        }

        #my-button,
        #my-button2 {
            border-radius: 5px;
            background-color: #0078d4;
            border: 1px solid #0078d4;
        }

        #my-button,
        #my-button3 {
             margin-right: 8px
        }

        #my-button2 {
            padding: 0px;

        }

        #my-button,
        #my-button3,
        #my-button2 {
            font-size: 12px;
        }
    }
}

.viewNode {
    .CloseBtn {
        padding-left: 9px;
    }

    .saveBtn {
        color: white;
    }
}

.credentialEdit {
    padding: 10px 14px !important;

    .credentialEditMain {
        width: 100%;

        .closeBtnDiv {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .heading {
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #0078d4;
            }
        }

        .credentialContentDiv {
            .nodeIdIv {
                display: flex;
                align-items: center;
                gap: 20px;
                justify-content: space-between;

                .nodeIdHeading {
                    font-size: 15px;
                    font-weight: 700 !important;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.25;
                    letter-spacing: 0.2px;
                    color: #000;
                }

                .nodeIdinput {
                    margin: 5px 0px;

                }
            }
        }

        .BtnDiv {
            padding: 5px;

            .btn-primary {
                background-color: rgb(0, 120, 212) !important;

                span.MuiButton-label {
                    font-weight: 700 !important;
                    color: white !important;
                }
            }

        }

    }
}

.modal-create-cluster {

    .dx-field {
        margin: 0px 0px 10px;

        @media only screen and (max-width:600px) {
            display: flex;
            flex-direction: column;
        }

        .dx-field-label {
            width: 28%;

            @media only screen and (max-width:600px) {
                width: 100%;
                margin-bottom: 8px;
            }
        }

        .dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
            width: 72%;

            @media only screen and (max-width:600px) {
                width: 100%;
            }
        }
    }

    .buttonDiv {
        display: flex;

        .btn-close {
            margin-left: 15px;
        }
    }
}

.create-cluster-node-modal {

    .dx-field {
        margin: 0px 0px 10px;

        @media only screen and (max-width:600px) {
            display: flex;
            flex-direction: column;
        }

        .dx-field-label {
            width: 28%;

            @media only screen and (max-width:600px) {
                width: 100%;
                margin-bottom: 8px;
            }
        }

        .dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
            width: 72%;

            @media only screen and (max-width:600px) {
                width: 100%;
            }
        }
    }

    .buttonDiv {
        display: flex;

        .btn-close {
            margin-left: 15px;
        }

    }
}

.modal-view-node {
    .dx-field {
        margin: 0px 0px 7px;

        .dx-field-label {
            width: 35%;
            font-weight: bold;
            font-size: 14px;
        }

        .dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
            width: 65%;
        }
    }

    .buttonDivs {
        margin-top: 15px;

        .closeBtns {
            float: right;

            @media screen and (max-width : 550px) {
                margin-bottom: 15px;
            }
        }
    }
}

.view-node-credentials {

    .dx-overlay-content {
        width: 480px;
        border-radius: 10px;
        box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.13);

        @media screen and (max-width : 550px) {
            width: 98% !important;
            height: auto !important;
            transform: translate(4px, 10px) !important;
        }

        .dx-popup-title.dx-toolbar {
            padding: 0 0 0 30px;
            border-bottom: none;
        }

        .dx-toolbar-label>div {
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.3px;
            color: #0078d4;
        }

        .closeBtns {
            float: left;

            .credential-btn {
                float: left;

                &.dx-button {
                    &.dx-button-default {
                        background-color: #707070;
                    }

                    .dx-button-text {
                        color: #fff;
                        font-weight: bold;
                        font-size: var(--font-text-size);
                        margin: 4px 4px;
                    }

                }

                .btn-role {
                    margin-right: 10px;
                }

            }
        }

        .dx-toolbar-after {
            display: none;
        }

        .dx-popup-content {
            padding: 3px 30px 28px 30px;
        }

        .dx-popup-bottom {
            display: none;
        }


    }
}

.create-cluster-form-popup.form-popup {
    .dx-form {
        .dx-layout-manager {
            .dx-field-item {
                padding-bottom: 8px;
                font-size: 14px;

                &:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
                    padding-top: 0px;
                }
            }


            .dx-texteditor {
                &.dx-editor-outlined {
                    .dx-texteditor-input {
                        padding: 8px 12px 8px;
                        min-height: 30px;
                    }

                    .dx-placeholder {
                        &::before {
                            padding: 8px 12px 8px;
                        }

                        &::after {
                            padding: 8px 12px 8px;
                        }
                    }
                }
            }

            .main-title {
                margin-bottom: 4px;

                .title {
                    font-size: 14px;
                    // font-weight: bold;
                }
            }
        }


        .dx-toolbar {
            .dx-toolbar-items-container {
                .btn-save {
                    margin-right: 8px;
                }
            }
        }
    }
}



.add-cluster-form-popup.form-popup {

    .dx-form {
        .dx-layout-manager {
            .dx-field-item {
                padding-bottom: 8px;
                font-size: 14px;

                &:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
                    padding-top: 0px;
                }
            }


            .dx-texteditor {
                &.dx-editor-outlined {
                    .dx-texteditor-input {
                        padding: 8px 12px 8px;
                        min-height: 30px;
                    }

                    .dx-placeholder {
                        &::before {
                            padding: 8px 12px 8px;
                        }

                        &::after {
                            padding: 8px 12px 8px;
                        }
                    }
                }
            }

            .main-title {
                margin-bottom: 4px;

                .title {
                    font-size: 14px;
                }
            }
        }


        .dx-toolbar {
            .dx-toolbar-items-container {
                .btn-save {
                    margin-right: 8px;
                }
            }
        }
    }
}