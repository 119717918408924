.create-share-details.form-popup {
    .dx-form {
        height: auto;

        .dx-field {
            margin: 0px 0px 15px;

            @media only screen and (max-width:600px) {
                display: flex;
                flex-direction: column;
            }

            .dx-field-label {
                font-size: 14px;
                font-weight: bold;
                // color: #222;
                width: 40%;

                @media only screen and (max-width:600px) {
                    width: 100%;
                }
            }

            .dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
                width: 60%;

                @media only screen and (max-width:600px) {
                    width: 100%;
                }
            }

            .dx-texteditor {
                &.dx-editor-outlined {
                    .dx-texteditor-input {
                        padding: 7px 12px 7px;
                        min-height: 30px;
                    }

                    .dx-placeholder {
                        font-size: 12px;

                        &::before {
                            padding: 7px 12px 7px;
                        }

                        &::after {
                            padding: 7px 12px 7px;
                        }
                    }
                }
            }
        }

        .data-share-btn {
            display: flex;
            justify-content: flex-end;

            .btn-close {
                margin-right: 10px;
            }

            .btn-create{
                color: #fff;
            }
        }
    }
}