$background-danger : #B72828;
$background-secondary: #68B92B;
$color-white: #fff;
$column-text-alignment: center;
$font-sm: 11px;
$border-radius-sm: 4px 4px 4px 4px;

.dashboard-cover .Innerheading .Innerheading {
    padding: 10px 7px;
    display: initial;
}

.grid.clusterCompare {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .DropdownDiv {
        display: flex;
        justify-content: flex-start;
        gap: 30px;

        @media screen and (max-width: 530px) {
            display: block;
        }

        .clusterDropdownDiv {
            display: flex;
            gap: 40px;
            align-items: center;
            width: 45%;

            @media screen and (max-width : 530px) {
                width: 100%;
            }

            .DropDown {
                width: 80%;
            }
        }

        .nodeDropdownDiv {
            display: flex;
            gap: 40px;
            align-items: center;
            width: 45%;

            @media screen and (max-width : 530px) {
                width: 100%;
                margin-top: 15px;
            }

            .DropDown {
                width: 80%;
            }
        }

        p.dropdownHeading {
            font-size: 12px;
            font-weight: 700;

            @media screen and (max-width : 530px) {
                width: 25px;
            }
        }
    }

    .compareBtn {
        padding-left: 80px;
    }

    .dx-button-content {
        padding: 6px 10px;
    }

    .dx-button-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
    }
}

.cluster-Compare {
    .SubHeader {
        .headDiv {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }

        .arrowBack {
            cursor: pointer;
        }
    }

    .view-wrapper-user-details {
        .Innerdetails {
            .dx-layout-manager {
                .dx-box-flex {
                    .dx-box-item {
                        .dx-item-content {

                            @media screen and (max-width : 550px) {
                                display: block !important;
                            }
                        }

                        .main-title {
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
    }
}


.view-wrapper {
    .dx-form .dx-layout-manager .dx-field-item {
        padding-bottom: 8px;
        font-size: 14px;
        font-weight: bold;

        @media screen and (max-width : 550px) {
            padding-left: 0;
            padding-right: 0;
        }

        &:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
            padding-top: 0px;
        }
    }

    .dx-texteditor {
        &.dx-editor-outlined {
            .dx-texteditor-input {
                padding: 8px 12px 8px;
                min-height: 30px;
            }

            .dx-placeholder {
                &::before {
                    padding: 8px 12px 8px;
                }

                &::after {
                    padding: 8px 12px 8px;
                }
            }
        }
    }

    .btn-compare {
        margin-top: 17px;
    }
}


.view-wrapper-cluster {
    .cluster-card {
        padding: 10px 15px 10px 15px;

        .cluster-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .cluster-title {
                .title {
                    font-size: 18px;
                    font-weight: 500;
                    text-transform: capitalize;
                    margin-bottom: 15px;
                    margin-bottom: 8px;

                    @media screen and (max-width: 767px) {
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }

            .cluster-filter-btn {
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;
            }
        }

        .cluster-table-list {
            margin-top: 10px;

            .statusData {
                .status-box-style {
                    color: $color-white;
                    min-width: 40px;
                    min-height: 15px;
                    float: left;
                    margin-top: 5px;
                    text-align: $column-text-alignment;
                    font-size: $font-sm;
                    border-radius: $border-radius-sm;
                }

                .down {
                    background-color: $background-danger;
                }

                .up {
                    background-color: $background-secondary;
                }

            }

        }
    }
}