@import "../../../../app.config.scss";




.adminsidebar {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 56px);

    .dx-drawer {
        flex: 1;

        .dx-drawer-panel-content {
            background: var(--background-color-default);
            width: $sidebar_width;
            position: relative;
            top: 0;
            transition: 0.3s;
            overflow: hidden;

            @media screen and (max-width: 767px) {
                width: 45px;
            }
        }

        .list-menu {

            li {
                list-style: none;
            }

            .dx-list-item-content {
                padding: 3px 0px;

                & .link {
                    display: flex;
                    text-decoration: none;
                    padding: 10px 12px 10px 12px;
                    box-sizing: border-box;

                    .devListIcon {
                        display: block;
                        padding-left: 6px;
                        min-width: 46px;

                        .svg-root svg {
                            width: 20px;
                        }
                    }

                    .dev-listRoot {
                        .text-title {
                            font-weight: 400;
                            letter-spacing: 0.18px;
                            line-height: 1.5;
                            color: var(--drawer-text-color);
                            font-size: 12px;
                        }
                    }

                    &.active {
                        border-left: 2px solid var(--active-sidebar-color);

                        .text-title {
                            color: #0078d4;
                        }
                        background-color: var(--active-sidebar-backgroundColor)
                    }
                }
            }


            .dx-list-item:last-child {
                // position: fixed;
                // left: 0;
                // bottom: 0;
                // width: $sidebar_width !important;
                margin-top: auto;
            }

        }
    }

    .dx-scrollview-content {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 56px);

        @media screen and (max-width : 767px) {
            height: calc(100vh - 120px);
        }

    }


    // li.menu-link.logoutitem {
    //     position: fixed;
    //     left: 0;
    //     bottom: 0;

    // }
}




.dx-drawer {
    .dx-drawer-panel-content {
        position: relative;
        border-right: 1px solid var(--border-color-default);
        width: $sidebar_width !important;
        top: 0px;
        transition: 0.3s;
        overflow: hidden;
        margin: 0 !important;
    }

}

.closestrip {
    .dev-listRoot {
        display: none;
    }

    .dx-drawer-panel-content {
        width: $sidebar_collapse_width !important;
        overflow: hidden;
        position: relative;
        top: 0;
        transition: 0.3s;
        box-shadow: 0 0 10px 0 rgba(67, 89, 113, 0.15);

        @media screen and (max-width: 767px) {
            width: 45px;
        }
    }
}