.Toastify{
    .Toastify__toast-container{
        .Toastify__toast{
            border-radius: 3px;
            color: white;
            .Toastify__toast-body{
                font-weight: 400;       
                font-size: 14px;
            }
            .Toastify__close-button{
                opacity: 1;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                &:hover{
                    background-color: rgba(0, 0, 0, 0.08);
                }
            }
            .Toastify__progress-bar {
                background-color: white;
            }
            &.Toastify__toast--success{
                background-color: #43a047;
            }
            &.Toastify__toast--error{
                background-color: #d32f2f;
            }
        }
    }
}