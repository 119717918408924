@import '../../../../shared/components/commonui/style/index.scss';


// .dx-popup-content {
//     .horizontal-grid {

//         .dx-datagrid {
//             width: 100%;

//             .dx-datagrid-table {
//                 margin-bottom: 10px;

//                 @media (max-width: 768px) {
//                     width: 100%;
//                     overflow-x: auto;
//                     white-space: nowrap;
//                     display: block;
//                 }

//                 tbody {
//                     min-width: 100%;
//                     border-collapse: collapse;

//                     tr {
//                         border-bottom: 1px solid $silver-gray;
//                         border-top: 1px solid $silver-gray;

//                         @media (max-width: 768px) {
//                             flex-wrap: nowrap;
//                         }

//                         td {
//                             padding: 6px 14px;
//                             font-size: $font-size-table;

//                             &.td-title {
//                                 font-size: $font-size-table;
//                                 color: $black-color;
//                                 font-weight: 700;
//                                 border-right: 1px solid $silver-gray;
//                             }

//                             @media (max-width: 768px) {
//                                 flex: 0 0 auto;
//                                 width: 50%;
//                                 box-sizing: border-box;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }

//     .org-btn {
//         text-transform: capitalize;
//         font-size: $btn-size-sm;
//         background-color: $modal-button-primaryColor !important;
//     }

//     .org-close {
//         text-transform: capitalize;
//         font-size: $btn-size-sm;
//         background-color: $modal-button-secondaryColor;
//         margin-left: 10px;
//     }

//     .form-container {
//         .create-content {
//             .create-inputText {
//                 display: flex;
//                 align-items: center;
//                 flex-direction: row;
//                 width: 100%;
//                 margin: 7px 0px;

//                 @media screen and (max-width: 768px) {
//                     flex-direction: column;
//                 }

//                 .create-label {
//                     width: 35%;
//                     // margin-right: 10px;
//                     // margin-top: 12px;

//                     @media screen and (max-width: 768px) {
//                         width: 100%;
//                         margin-top: 8px;
//                     }

//                     .label {
//                         // font-size: 1rem;
//                         font-weight: 700;
//                         // color: $black-color;

//                         @media screen and (max-width: 768px) {
//                             font-size: $btn-size-sm;
//                         }
//                     }
//                 }

//                 .create-grid {
//                     width: 55%;
//                     // margin-top: 12px;

//                     .InputClass {
//                         // margin-bottom: 10px;
//                     }


//                     @media screen and (max-width: 768px) {
//                         width: 100%;
//                         margin-top: 8px;
//                     }

//                     .dx-texteditor.dx-editor-outlined {
//                         width: 100%;

//                         & .dx-texteditor-input {
//                             padding: 10px 16px 9px;
//                         }

//                         & .dx-placeholder::before {
//                             padding: 10px 9px 8px;
//                         }
//                     }



//                     //   Use Button
//                     .btn-create.dx-button {
//                         .dx-button-content {
//                             padding: 6px 10px;
//                             background-color: $modal-button-primaryColor;
//                         }

//                         .dx-button-text {
//                             text-transform: capitalize;

//                         }
//                     }

//                     .btn-close.dx-button {
//                         margin-left: 10px;

//                         .dx-button-content {
//                             padding: 6px 10px;
//                             background-color: $modal-button-secondaryColor;
//                         }

//                         .dx-button-text {
//                             text-transform: capitalize;
//                             color: $white-color;
//                         }
//                     }


//                 }

//                 .btn-contract-generate {
//                     width: 10%;
//                     margin-top: 10px;
//                     margin-left: 5px;

//                     .generate-btn.dx-button {
//                         .dx-button-content {
//                             padding: 6px 12px;
//                         }

//                         .dx-button-text {
//                             text-transform: capitalize;
//                             font-size: $btn-size-sm;
//                             color: $white-color;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }


.organization-details {
    .horizontal-grid {
        .dx-datagrid {
            width: 100%;

            .dx-datagrid-rowsview {
                border: none;
            }

            .dx-datagrid-table {
                margin-bottom: 10px;

                @media (max-width: 768px) {
                    width: 100%;
                    overflow-x: auto;
                    white-space: nowrap;
                    display: block;
                }

                tbody {
                    min-width: 100%;
                    border-collapse: collapse;

                    tr {
                        border-bottom: 1px solid $silver-gray;
                        border-top: 1px solid $silver-gray;

                        @media (max-width: 768px) {
                            flex-wrap: nowrap;
                        }

                        td {
                            padding: 6px 14px;
                            font-size: $font-size-table;

                            &.td-title {
                                font-size: $font-size-table;
                                font-weight: 700;
                                border-right: 1px solid $silver-gray;
                            }

                            @media (max-width: 768px) {
                                flex: 0 0 auto;
                                width: 50%;
                                box-sizing: border-box;
                            }
                        }
                    }
                }
            }
        }
    }
}


.edit-organization-details.form-popup {
    .dx-form {
        height: auto;

        .dx-field {
            margin: 0px 0px 15px;

            @media only screen and (max-width:600px) {
                display: flex;
                flex-direction: column;
            }

            .dx-field-label {
                font-size: 14px;
                font-weight: bold;
                // color: #222;
                width: 30%;

                @media only screen and (max-width:600px) {
                    width: 100%;
                }
            }

            .dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
                width: 70%;

                @media only screen and (max-width:600px) {
                    width: 100%;
                }
            }

            .dx-texteditor {
                &.dx-editor-outlined {
                    .dx-texteditor-input {
                        padding: 7px 12px 7px;
                        min-height: 30px;
                    }

                    .dx-placeholder {
                        font-size: 12px;

                        &::before {
                            padding: 7px 12px 7px;
                        }

                        &::after {
                            padding: 7px 12px 7px;
                        }
                    }
                }
            }
        }

        .org-btn {
            display: flex;
            justify-content: flex-end;

            .edit-cancel {
                margin-right: 10px;
            }
        }
    }
}