$prefix: 'ty' !default;

// Color
$primary-color: #6e41bf !default;

$white-color: #fff !default;
$gray-100: #f6f9fc !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #8898aa !default;
$gray-700: #525f7f !default;
$gray-800: #32325d !default;
$gray-900: #212529 !default;
$black-color: #000 !default;
$charcoal-color: #434b52 !default;
$grayish-blue: #d8dbe0 !default;

$red-color: #dc3545 !default;
$orange-color: #fd7e14 !default;
$yellow-color: #fadb14 !default;
$green-color: #52c41a !default;
$teal-color: #20c997 !default;
$cyan-color: #17a2b8 !default;
$blue-color: #0d6efd !default;
$indigo-color: #6610f2 !default;
$purple-color: #6f42c1 !default;
$magenta-color: #eb2f96 !default;
$silver-gray: #e0e0e0 !default;


// Info
$info-color: #1890ff !default;
$info-light-color: #91d5ff !default;
$info-lighter-color: #e6f7ff !default;
$dodger-blue: #03a9f4 !default;

// Success
$success-color: #52c41a !default;
$success-light-color: #b7eb8f !default;
$success-lighter-color: #f6ffed !default;

// Warn
$warning-color: #ff9800 !default;
$warning-light-color: #ffe58f !default;
$warning-lighter-color: #fffbe6 !default;

// Error
$danger-color: #f44336 !default;
$danger-light-color: #ffa39e !default;
$danger-lighter-color: #fff1f0 !default;

// Body
$body-bg-color: $white-color !default;
$body-color: $gray-800 !default;

// Font & Font family
$font-color: $body-color !default;
$font-path: 'fonts' !default;
$font-size-base: 1rem !default;
$font-size-md: 1.125rem !default;
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;
$font-weight: 400 !default;
$font-weight-700: 700 !default;


$font-family-sans-serif: -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: lucida console, consolas, monaco, andale mono, ubuntu mono, monospace !default;
$font-family: $font-family-sans-serif !default;

$height-sm: 24px !default;
$height-md: 32px !default;
$height-lg: 42px !default;

$line-height-base: 1.5 !default;
$line-height-lg: 2 !default;
$line-height-sm: 1.25 !default;

// Responsive breakpoints
$size-xs: 480px !default;
$size-sm: 600px !default;
$size-md: 840px !default;
$size-lg: 960px !default;
$size-xl: 1280px !default;
$size-xxl: 1440px !default;

// Layout
$layout-body-background: #fff !default;
$layout-header-background: #fff !default;
$layout-header-padding: 0 50px !default;
$layout-header-height: 60px !default;
$layout-footer-background: #fff !default;
$layout-footer-padding: 24px 50px !default;
$layout-sidebar-background: #12131a !default;

// Spacing
$spacer: 1rem !default;

// Typography
$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$headings-margin-bottom: calc($spacer / 2) !default;
$headings-font-family: null !default;
$headings-font-style: null !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;

// Border & Border Radius
$border-radius: 2px !default;
$border-width: 1px !default;
$border-color: $gray-300 !default;

// Box shadow
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black-color, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black-color, 0.15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black-color, 0.175) !default;
$box-shadow-inset: inset 0 1px 2px rgba($black-color, 0.075) !default;

// Code
$code-font-size: 0.875em !default;
$code-color: $gray-800 !default;
$pre-color: null !default;

// Link
$link-color: $primary-color !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;

// Alert
$alert-border-radius: 3px !default;

// Avatar
$avatar-bg: #ccc !default;
$avatar-color: #fff !default;
$avatar-border-radius: 2px !default;

// Collapse
$collapse-border-radius: $border-radius !default;

// Badge
$badge-font-size: 12px !default;
$badge-size: 18px !default;
$badge-dot-size: 6px !default;

// Button
$btn-font-size-sm: $font-size-sm !default;
$btn-font-size-md: $font-size-base !default;
$btn-font-size-lg: $font-size-lg !default;
$btn-padding-sm: 0 10px !default;
$btn-padding-md: 0 15px !default;
$btn-padding-lg: 0 28px !default;
$btn-height-sm: $height-sm !default;
$btn-height-md: $height-md !default;
$btn-height-lg: $height-lg !default;
$btn-line-height: $line-height-base !default;
$btn-border-radius: $border-radius !default;
$btn-border-width: $border-width !default;
$btn-font-weight: 400 !default;
$btn-font-family: $font-family !default;
$btn-box-shadow: inset 0 1px 0 rgba($white-color, 0.15), 0 1px 1px rgba($black-color, 0.075) !default;
$btn-loading-opacity: 0.35 !default;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

// Card
$card-border-radius: $border-radius !default;
$card-header-padding: 13px 16px !default;
$card-body-padding: 16px !default;
$card-footer-padding: 5px 16px 16px !default;

// Divider
$divider-line-color: #e4e4e4 !default;

// Input
$input-font-color: $font-color !default;
$input-border-radius: $border-radius !default;
$input-sm-padding: 0 4px !default;
$input-sm-height: $height-sm !default;
$input-sm-font-size: $font-size-sm !default;
$input-md-padding: 0 6px !default;
$input-md-font-size: $font-size-base !default;
$input-md-height: $height-md !default;
$input-lg-padding: 0 8px !default;
$input-lg-font-size: $font-size-lg !default;
$input-lg-height: $height-lg !default;

// Textarea
$textarea-font-size: $font-size-base !default;
$textarea-padding: 5px !default;

// Native Select
$native-select-sm-padding: 3px 25px 3px 7px !default;
$native-select-sm-font-size: $font-size-sm !default;
$native-select-md-padding: 6px 25px 6px 7px !default;
$native-select-md-font-size: $font-size-base !default;
$native-select-lg-padding: 9px 25px 9px 7px !default;
$native-select-lg-font-size: $font-size-lg !default;
$native-select-border-radius: $border-radius !default;

// Switch
$switch-md-font-size: 12px !default;
$switch-sm-font-size: 9px !default;
$switch-lg-font-size: 14px !default;

// Select
$select-selected-font-weight: 600 !default;
$select-dropdown-max-height: 300px !default;
$select-dropdown-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !default;

// Notification
$notification-width: 380px !default;
$notification-margin: 20px !default;

// Breadcrumb
$breadcrumb-font-size: $font-size-base !default;

// Tag
$tag-border-radius: $border-radius !default;

// Popover
$popover-arrow-size: 8px !default;
$popover-border-radius: $border-radius !default;
$popover-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !default;

// Tooltip
$tooltip-arrow-size: 4px !default;
$tooltip-font-size: $font-size-sm !default;
$tooltip-content-padding: 5px 8px !default;

// Menu
$menu-sub-border-radius: $border-radius !default;
$menu-item-padding-vertical: 15px 20px !default;

// Slider
$slider-size: 12px !default;
$slider-track-size: 4px !default;
$slider-margin-bottom-with-marks: 30px !default;

// Steps
$steps-title-font-size: 16px !default;
$steps-title-active-color: $primary-color !default;
$steps-title-normal-color: rgba(0, 0, 0, 0.65) !default;
$steps-title-wait-color: rgba(0, 0, 0, 0.25) !default;
$steps-title-error-color: #ff4d4f !default;
$steps-desc-active-color: $primary-color !default;
$steps-desc-normal-color: rgba(0, 0, 0, 0.45) !default;
$steps-desc-wait-color: rgba(0, 0, 0, 0.25) !default;
$steps-desc-error-color: #ff4d4f !default;

// Description
$description-border-color: #dfe2e5 !default;
$description-border-radius: $border-radius !default;
$description-sm-padding-vt: 8px !default;
$description-md-padding-vt: 12px !default;
$description-lg-padding-vt: 16px !default;
$description-sm-padding-hr: 16px !default;
$description-md-padding-hr: 24px !default;
$description-lg-padding-hr: 24px !default;

// Tree
$tree-font-size: $font-size-base !default;

// StrengthIndicator
$strength-indicator-border-radius: 99px !default;

$modal-button-primaryColor : #0078d4 !default;
$modal-button-secondaryColor : #9c9c9a !default;


// Table
$font-size-table: 13px !default;
$header-table-color: #9b9595 !default;


// Button Size
$btn-size-sm: 14px !default;



// Header 
