@import "../../../../assets/sass/styles.scss";


.mainside {
  .dx-drawer {
    height: calc(100vh - #{$header_height});

    .dx-drawer-panel-content {
      background: var(--background-color-default);
      width: $sidebar_width;
      position: relative;
      height: calc(100vh - 45px);
      top: 0;
      transition: 0.3s;
      overflow: hidden;

      @media screen and (max-width: 767px) {
        width: 45px;
      }
    }

    .rightmenu {
      width: 100%;

      li {
        list-style: none;
      }

      .dx-list-item-content {
        padding: 3px 0px;

        .link {
          display: flex;
          text-decoration: none;
          padding: 10px 12px 10px 12px;
          box-sizing: border-box;

          .devListIcon {
            display: block;
            padding-left: 6px;
            min-width: 46px;

            .svg-root svg {
              width: 20px;
            }
          }

          .dev-listRoot {
            min-width: 90px;
            white-space: break-spaces;

            .text-title {
              font-weight: 400;
              letter-spacing: 0.18px;
              line-height: 1.5;
              color: var(--drawer-text-color);
              font-size: 12px;
            }
          }

          &.active {
            border-left: 2px solid var(--active-sidebar-color);
            background-color: var(--active-sidebar-backgroundColor);

            .text-title {
              color: #0078d4;
            }
          }
        }
      }
    }
  }

  .menu-btns {
    display: flex;
    justify-content: center;
    padding: 30px 0px 10px 0px;
    border-right: 1px solid var(--border-color-default);
    background-color: var(--background-color-default);
    box-shadow: rgba(67, 89, 113, 0.15) 0px 0px 10px 0px;

    i{
      cursor: pointer;
    }
  }
}

span.logo img {
  height: 33px;
}


.closestrip {
  .dev-listRoot {
    display: none;
  }

  .dx-drawer-panel-content {
    width: $sidebar_collapse_width !important;
    overflow: hidden;
    position: relative;
    height: calc(100vh - 45px);
    top: 0;
    transition: 0.3s;

    @media screen and (max-width: 767px) {
      width: 45px;
    }
  }
}