@import "../../../assets/sass/global-import";
@import "../../../app.config.scss";

:root {
  --font-text-size: 12px;
  --background-color: #fff;
  --tab_background_color: #ffffff;
  --font-color: #000000;

}

input,
strong,
label,
span,
div,
textarea,
button,
body,
li,
a,
b,
hr,
br,
h1,
h2,
h3,
h4,
h5,
h6,
legend,
nav,
option,
p,
select,
table,
td,
tr,
tbody,
thead {
  font-family: $font-family;
}

.btn-primary {
  background-color: $primary;
  color: $secondary;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  font-size: var(--font-text-size);

  svg.MuiSvgIcon-root {
    font-size: var(--font-text-size);
    margin-right: 4px;
  }

  //   &:hover {
  //     color: $secondary;
  //     background-color: #044c92 !important;
  //     border-color: $primary !important;
  // }
  &:focus {
    box-shadow: none !important;
    background: $primary !important;
  }
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}

tr.MuiTableRow-root.MuiTableRow-head {
  background: $primary;

  th.MuiTableCell-root.MuiTableCell-head {
    font-weight: 600;
    border-bottom: 0px solid $primary;
    padding: 0px 10px;
    background: var(--border_color1);
  }
}

.dashboard-cover {
  margin-top: 1%;
  // background: var(--background-color);
  // background-color: rgb(250, 250, 250) !important;
  background-color: var(--main-background-color) !important;
  max-height: calc(100vh - 34px);
  @include custom-scroller("y");


  @media screen and (max-width : 539px) {
    max-height: calc(100vh - 120px);
  }

  @media screen and (max-width: 480px) {
    margin-left: 0;
  }

  @media screen and (max-width: 767px) and (min-width: 481px) {
    margin-left: 14%;
  }

  @media screen and (max-width: 1024px) and (min-width: 768px) {
    margin-left: 19%;
  }

  @media screen and (max-width: 1300px) and (min-width: 1024px) {
    margin-left: 14%;
  }

  .Innerheading {
    padding: 0px;
    // background: $secondary;
    background-color: rgb(250, 250, 250);
    // margin-bottom: 10px;

    @media screen and (max-width: 767px) {
      // padding: 7px 14px;
      max-height: calc(100vh - 0px);
      // overflow-y: auto;
      // overflow-x: hidden;
    }

    .InnerHeader {
      // background-color: $headbackground_color;
      background-color: $background_color;
      padding: 5px 15px;
      box-shadow: 1px 1px 11px 3px rgba(0, 0, 0, 0.3);
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: space-between;
      border-radius: 4px;

      .blanks {
        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      h3 {
        margin: 0px;
        font-size: 18px;
        font-weight: bold;
        font-family: $font-family;
        color: $font-color;

        @media screen and (max-width: 767px) {
          font-size: var(--font-text-size);
        }
      }

      form {
        label {
          margin-bottom: 0px;
        }
      }
    }

    .Innerdetails {
      padding: 10px 14px;
      background: var(--background-color);

      @media screen and (max-width: 767px) {
        padding: 10px 7px;

      }

      @media screen and (max-width: 1024px) and (min-width: 768px) {
        padding: 10px 7px;
      }

      @media screen and (max-width: 1300px) and (min-width: 1024px) {
        padding: 10px 7px;
      }

      .actionBtn {
        span {
          color: #fff;
        }
      }

      .maindiv {
        display: flex;
        text-align: center;
        align-items: center;
        height: 100vh;
        justify-content: center;

        h4 {
          font-size: 30px;
          font-weight: 600;
          color: $primary;
        }
      }
    }
  }

  .Container {
    @media screen and (max-width: 767px) {
      margin-top: 4%;
      padding: 0% 2%;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
      margin-top: 2%;
      padding: 0% 2%;
    }

    @media screen and (max-width: 1300px) and (min-width: 1024px) {
      margin-top: 1%;
      padding: 0% 0%;
    }
  }
}

// .sidebar-width {
//   margin-left: 10%;

//   @media screen and (max-width: 767px) {
//     margin-left: 14%;
//   }
//   @media screen and (max-width: 1024px) and (min-width: 768px) {
//     margin-left: 19%;
//   }
//   @media screen and (max-width: 1300px) and (min-width: 1024px) {
//     margin-left: 14%;
//   }
// }
.dynamic-list {
  .footer {
    display: flex;
    justify-content: space-around;

    .MuiTypography-colorInherit {
      font-weight: 900;
      color: $primary;
    }
  }

  .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
    background: var(--background-color);
  }
}

.tab-color {
  // background: #e9eaef !important;
  // background: var(--tab_background_color);
  // color: var(--font-color) !important;

  span[class*="PrivateTabIndicator-colorSecondary"] {
    background-color: $primary;
  }

  .MuiTab-wrapper {
    font-weight: bold;
    text-transform: capitalize;
    font-size: var(--font-text-size);
  }
}

.form-heading {
  font-size: $page_heading_font_size;
}

.multi {
  .MuiFormControl-root {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 100%;
    flex-direction: column;
    vertical-align: top;

    .MuiChip-root {
      padding: 8px;
      margin: 4px;
    }
  }
}

.modalpopoup {
  .MuiDialogTitle-root {
    // color: $primary;
    color: var(--font-color);
  }

  .MuiTableRow-root {
    &:last-child {
      &:hover {
        background: transparent !important;
      }
    }
  }

  .MuiTableBody-root {
    .MuiTableCell-root {
      font-size: var(--font-text-size);
      // color: var(--font-color);
      color: var(--url-color);

      strong {
        font-weight: 700;
        // color: $primary;
        color: var(--font-color);
      }
    }
  }

  .MuiDialogContent-dividers {
    padding: 16px 0px;
  }
}

.react-confirm-alert-overlay {
  background: rgba(9, 9, 9, 0.52);

  .react-confirm-alert-body {
    padding: 12px;
    width: 300px;
    text-align: center;
    font-size: 14px;

    h1 {
      font-size: 16px;
      font-weight: 600;
      color: $primary;
    }

    .react-confirm-alert-button-group {
      justify-content: center;

      button {
        background-color: $primary;
        color: $secondary;
      }
    }
  }
}

.btn-primary:disabled {
  color: #fff !important;
  background-color: #044c92b3 !important;
  border-color: #044c92b3 !important;
  opacity: 0.65;
}

.blank {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.MuiTab-root {
  &:hover {
    background-color: $hover_background_color;
  }
}