$sidebar_width: 150px;
$sidebar_collapse_width: 60px;
$header_height: 50px;
$header_height_mobile: 40px;
$primary: #0078d4;
$secondary: #fff;
$background: #ffffff;
$headbackground_color: #f2f4f7;
$hover_color: #c1c1c175;
$icon_font_size: 15px;
$font_icon: 18px;
$page_heading_font_size: 20px;
$font-text-size: 12px;
$content-heading-size: 14px;
$schema-page-font-text-size: 16px;
$strong-font-size: 12px;
$p-font-size: 12px;
$span-font-size: 13px;
$font-family: "Roboto",sans-serif;
$secondary-font: "Roboto",sans-serif;
$font-color: #000000;
$background_color: #f3f2f1;
$selected_font_color: #005a9e;
$selected_background_color: #c7e0f4;
$hover_background_color: #edebe9;
$tab_background_color: #ffffff;
$border_color: #ccc;
$table_header_color: #ccccccd6;
$header_background_color:#b3d4ff;
$first-family: "Arial";
$landing-page-first-font-size:16px;
$landing-page-second-font-size:14px;
$landing-page-third-font-size:12px;
