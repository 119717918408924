@import "../../assets/themes/default/share.scss";

body {
  margin: 0px;
  padding: 0px;


  /* Innerheadinglandingtheme1 */

  .Innerheadinglandingtheme1 {
    .header {
      margin: -1%;

      @media screen and (max-width: 768px) {
        // min-height: 100px;
        // max-height: 380px;
      }

      @media screen and (max-width: 480px) {
        // min-height: 100px;
        // max-height: 380px;
      }

      .headertitle {
        display: flex;
        background-color: $primary;
        height: auto;

        .headeraction {
          display: flex;
          width: 100%;
          align-items: center;

          @media screen and (max-width: 480px) {
            // flexWrap: wrap;
          }


          @media screen and (max-width: 768px) {
            flex-direction: column;
            margin: 0px 2px;
          }

          @media screen and (max-width: 959px) {
            flex-direction: column;
            margin: 0px 2px;
          }

          .selecttheme {
            display: flex;
            text-align: center;
            align-items: center;
            width: 256px;
            color: white;
            font-size: var(--h3-font-size);
            // margin-top: 8px;

            @media screen and (max-width: 768px) {
              display: block;
              text-align: left;
              padding: 1px 0px;
              width: 97.5%;
            }

            @media screen and (max-width: 480px) {
              display: block;
              width: 98%;
            }

            @media screen and (max-width: 959px) {
              display: block;
              text-align: left;
              padding: 1px 0px;
              width: 97.5%;
            }


            .statuslabel {
              // width: 45%;
              margin-right: 10px;

              @media screen and (max-width:768px) {
                margin-left: 10px;
              }

              @media screen and (max-width: 480px) {
                width: 23%;
                margin-left: 5px;
              }

              @media screen and (max-width:959px) {
                margin-left: 10px;
              }
            }

            .themelabel {
              width: 109%;

              @media screen and (max-width:768px) {
                margin-left: 5px;
              }

              @media screen and (max-width:959px) {
                margin-left: 5px;
              }
            }

            .themelabel1 {
              margin-top: 3%;
              width: 65%;

              @media screen and (max-width:768px) {
                margin-left: 10px;
              }

              @media screen and (max-width: 480px) {
                margin-top: 0%;
                width: 25% !important;
                margin-left: 5px;
              }

              @media screen and (max-width:959px) {
                margin-left: 10px;
              }
            }

            select.form-control {
              height: 39px;
              width: 100%;
              margin-left: 1%;
              border-radius: 5px;
              font-size: var(--font-text-size);

              @media screen and (max-width: 480px) {
                width: 100%;
                height: 34px;
              }

              @media screen and (max-width: 959px) {
                width: 100%;
                height: 34px;
              }
            }

            select.form-control1 {
              height: 41px;
              width: 54%;
              margin-left: 1%;
              border-radius: 5px;
              font-size: var(--font-text-size);

              @media screen and (max-width: 768px) and (min-width: 481px) {
                margin-top: 0%;
                width: 100%;
              }

              @media screen and (max-width: 480px) {
                height: 34px;
                margin-top: 0%;
                width: 100%;
              }

              @media screen and (max-width: 959px) {
                height: 34px;
                margin-top: 0%;
                width: 100%;
              }
            }
          }

          .login-form {
            display: flex;
            align-items: center;
            margin: 1% 1%;
            margin-top: 1%;
            width: 78.5%;
            font-size: var(--font-text-size);

            @media screen and (max-width: 768px) {
              margin: 5px;
              display: block;
              width: 100%;
              order: 2;
            }

            @media screen and (max-width: 959px) {
              margin: 5px;
              display: block;
              width: 100%;
              order: 2;
            }

            @media screen and (max-width: 480px) {
              width: 100%;
              display: block;
            }
          }

          .sign-in-btn {
            padding: 9px 20px;
            color: white;
            width: 98%;
            margin-top: 6px;

            @media screen and (max-width: 768px) {
              padding: 12px 4px;
              display: flex;
              justify-content: flex-end;
              margin-top: -47px;
            }

            @media screen and (max-width: 959px) {
              padding: 12px 4px;
              display: flex;
              justify-content: flex-end;
              margin-top: -53px;
            }

            .logodiv {
              display: flex;
              // margin-left: -18px;
            }

            .user-name {
              margin-top: 10px;
              width: 100%;
              font-size: var(--h3-font-size);
            }

            svg.MuiSvgIcon-root {
              font-size: var(--font-text-size);
              margin-right: 8px;
              color: white;
            }

            .MuiButton-text {
              padding: 4px 8px;

              @media screen and (max-width: 767px) {
                margin-top: 0px;
              }
            }
          }

          .form-group {
            display: flex;
            align-items: center;
            color: white;

            @media screen and (max-width: 768px) {
              display: block;
              margin-left: 0.5%;
            }

            @media screen and (max-width: 959px) {
              display: block;
              margin-left: 0.5%;
            }

            .MuiFormControl-marginNormal {
              padding: 1px 20px;
              margin-top: 0px;
              margin-bottom: 0px;

              @media screen and (max-width: 768px) {
                padding: 1px 0px;
              }

              @media screen and (max-width: 959px) {
                padding: 1px 0px;
              }
            }

            input#standard-password-input {
              padding: 7px 7px;
            }

            input#standard-uncontrolled {
              padding: 7px 7px;
            }
          }

          .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
            height: 31px;
            background-color: white;
          }

          button.btn-primary {
            background-color: #e63737 !important;
            font-size: 14px !important;
            color: white;
            text-transform: none;
            font-weight: bold;
            width: 100px;

            @media screen and (max-width: 768px) {
              margin-top: 17px;
              width: 150px;
            }

            @media screen and (max-width: 959px) {
              margin-top: 17px;
            }
          }

          button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit {
            color: white;

            @media screen and (max-width:768px) {
              order: 2;
              max-width: max-content;
              align-self: flex-end;
            }

            @media screen and (max-width:959px) {
              order: 2;
              max-width: max-content;
              align-self: flex-end;
            }
          }
        }
      }

      .table-header-title {
        text-align: left;
        // margin-left: 5.5%;
        margin: -16px 0px -20px 80px;

        h2 {
          font-family: $first-family;
          font-size: 32px;
          color: white;
          width: 94%;

        }

        @media screen and (max-width: 768px) {
          margin-left: 1%;
          align-items: center;

          h2 {
            font-size: 25px;
            width: 94%;

            @media screen and (max-width: 480px) {
              margin-top: 9%;
              font-size: 23px;
            }
          }
        }

        span p {
          font-family: $first-family;
          font-size: var(--font-text-size); //14px;
          color: white;
        }

        .sub-header {
          display: flex;
          align-items: center;
          margin-left: 5px;

          .title {
            @media screen and (max-width: 480px) {
              margin-top: 5%;
            }

            @media screen and (max-width:768px) {
              margin-top: 5%;
            }
          }

          .logo {
            //  width: 4%;
            margin-right: 15px;

            @media screen and (max-width: 768px) {
              width: 7%;
              margin-top: 2%;
            }

            @media screen and (max-width: 480px) {
              width: 13%;
              margin-top: 6%;
            }

            span.logo img {
              height: 33px;
            }
          }
        }

        .textsearch {
          box-shadow: 4px 1px 6px 3px rgba(9, 9, 9, 88);
          width: 92%;
          margin-left: 1%;
          margin-top: -1%;
          margin-bottom: 6%;
          border-radius: 5px;

          .search {
            background-color: transparent;
          }

          .MuiInputBase-input {
            color: white;
          }

          .MuiIconButton-label {
            color: white;
          }

          button.MuiButtonBase-root.MuiIconButton-root.jss2.jss4 {
            color: white;
          }

          @media screen and (max-width: 1160px) and (min-width: 960px) {
            width: 88%;
            margin-bottom: 12%;
          }

          @media screen and (max-width: 959px) and (min-width: 766px) {
            width: 89%;
            margin-left: 0.5%;
            margin-bottom: 3%;
          }

          @media screen and (max-width: 768px) {
            width: 99%;
            margin-left: 0%;
            margin-top: -1%;
            margin-bottom: 6%;
          }
        }
      }

      .landingmaintheme1 {
        display: flex;
        margin: 0px 80px;
        border-radius: 10px;
        height: calc(45vh - 1px);
        justify-content: space-between;

        @media screen and (max-width: 768px) {
          display: block;
          margin: 0px 2px;
        }

        p {
          font-size: var(--font-text-size); //$landing-page-third-font-size;
          font-family: $first-family;
          color: white;
        }

        .content-heading {
          border-bottom: 1px solid darkslateblue;
          background: $table_header_color;
          padding: 5px 0px;
          height: 22px;
          color: $font-color;
          display: flex;
          justify-content: space-between;
          text-align: center;
          align-items: center;

          span {
            font-size: var(--font-text-size); //$landing-page-third-font-size;
            font-family: $font-family;
            font-weight: 600;
            text-transform: capitalize;
          }

          strong {
            font-size: $landing-page-third-font-size;
            width: auto;
            font-family: $font-family;

            svg.MuiSvgIcon-root {
              width: 15px;
              height: 15px;
              vertical-align: sub;
            }
          }
        }

        .important-details {
          height: calc(18vh - 10px);
          border-bottom: 1px solid darkslateblue;
          margin: 6px;
        }

        .MuiCardHeader-root {
          display: flex;
          padding: -2px;
          align-items: center;
          border: 1px solid #ccc;
          background: $primary;
          color: #000;
          justify-content: space-between;
        }

        .MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded {
          //box-shadow: 2px 1px 10px 3px rgba(0, 0, 0, 0.3);
          background-color: transparent;
          border: 1px solid transparent;
        }

        .MuiCardContent-root {
          @include custom-scroller("xy");
          height: 37px;
        }

        .importantcontent {
          height: 92%;
          overflow: auto;

          .tableContainerDiv {
            display: flex;
            align-items: center;
            // height: calc(8vh - 0px);
            border-bottom: 1px solid darkslateblue;


          }

          span.icon {
            padding: 9px 14px;

            svg.MuiSvgIcon-root {
              height: 25px;
              width: 25px;
              margin-right: 5%;
              border-radius: 40%;
              background: $primary;
              color: white;
              padding: 2px 0px;
            }
          }
        }

        .table-title {
          background-color: $header_background_color !important;
          height: 40px;
          justify-content: center;

          div {
            text-align: center;
          }

          .iconclass {
            padding: 12px 12px;
          }
        }





        .schemalink {
          margin-left: 8px;
        }



        .content-label {
          float: left;
          margin-right: -18%;
          font-family: $font-family;
        }

        table {
          font-family: $font-family;
          border-collapse: collapse;
          width: 100%;
        }

        td,
        th {
          text-align: left;
          // padding: 8px;
        }

        tr.MuiTableRow-root.MuiTableRow-head {
          height: 33px;
          background-color: transparent !important;
          border: 1px solid dodgerblue;
        }

        .MuiTableBody-root .MuiTableCell-root {
          padding: 2px 10px !important;
          border-bottom-color: darkslateblue;
        }

        .headercontent {
          padding: 1px 0px;
          height: 50%;

          h3 {
            text-align: left;
            font-size: var(--h3-font-size);
            font-family: $first-family;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: white;
          }

          .MuiGrid-justify-xs-space-between {
            min-height: 40px;
            padding: 0px 19px;
          }

          .table-header {
            border-bottom: 1px solid darkslateblue;
            margin: 0% 5%;

            .release {
              //height: 14px;
              font-family: $first-family;
              font-size: var(--font-text-size);
              letter-spacing: normal;
              font-weight: normal;
              color: white;
            }

            .our-online-presence {
              font-family: $first-family;
              font-size: var(--font-text-size);
              color: white;
            }

            p {
              font-size: var(--font-text-size);
              margin-top: -3%;
              font-family: $first-family;
            }

            h3 {
              width: 100%;
              font-family: $first-family;
              font-size: var(--h3-font-size);
              color: white;
            }

            .productlisturl {
              font-family: $first-family;
              font-size: var(--font-text-size);
              font-weight: bold;
              color: white;

              a {
                color: white;
                text-decoration: blink;
                font-family: $first-family;
              }

              span {
                cursor: pointer;
                font-size: var(--font-text-size);
                font-weight: bold;
                color: white;
                font-family: $first-family;
              }
            }

            .productIcon {
              margin-right: 3%;
            }

            .producturl {
              font-family: $first-family;
              font-size: var(--font-text-size);
              color: white;
              cursor: pointer;
              display: flex;
              align-items: center;
              margin-top: 2%;

              .product-document {
                margin-left: 10px;
                color: white;
              }

              svg.MuiSvgIcon-root {
                color: var(--link-color);
                margin-right: 3%;
              }
            }

            .tableContainerRoot {
              .product-listing {
                font-family: $first-family;
                font-size: var(--font-text-size);
                font-weight: normal;
                color: white;
              }
            }

            .productRelease {
              width: 104%;
              overflow: auto;
              height: calc(30vh - 10px);
            }

            .suport-desk {
              width: 110px;
              font-family: $first-family;
              font-size: var(--font-text-size);
              color: #000000;
            }
          }

          .table-header-large {
            border-bottom: 1px solid darkslateblue;
            margin: 8% 5%;

            .release {
              //height: 14px;
              font-family: $first-family;
              font-size: var(--font-text-size);
              letter-spacing: normal;
              font-weight: normal;
              color: white;
            }

            .our-online-presence {
              font-family: $first-family;
              font-size: var(--font-text-size);
              color: white;
            }

            p {
              font-size: var(--font-text-size);
              margin-top: -3%;
              font-family: $first-family;
            }

            h3 {
              width: 100%;
              font-family: $first-family;
              font-size: var(--h3-font-size);
              color: white;
            }

            .productlisturl {
              font-family: $first-family;
              font-size: var(--font-text-size);
              font-weight: bold;
              color: white;

              a {
                color: white;
                text-decoration: blink;
                font-family: $first-family;
              }

              span {
                cursor: pointer;
                font-size: var(--font-text-size);
                font-weight: bold;
                color: white;
                font-family: $first-family;
              }
            }

            .productIcon {
              margin-right: 3%;
            }

            .producturl {
              font-family: $first-family;
              font-size: var(--font-text-size);
              color: white;
              cursor: pointer;
              display: flex;
              align-items: center;
              margin-top: 2%;

              .product-document {
                margin-left: 10px;
                color: white;
              }

              svg.MuiSvgIcon-root {
                color: var(--link-color);
                margin-right: 3%;
              }
            }

            .tableContainerRoot {
              .product-listing {
                font-family: $first-family;
                font-size: var(--font-text-size);
                font-weight: normal;
                color: white;
              }
            }

            .productRelease {
              width: 104%;
              overflow: auto;
              height: calc(30vh - 10px);
            }

            .suport-desk {
              width: 110px;
              font-family: $first-family;
              font-size: var(--font-text-size);
              color: #000000;
            }
          }
        }

        .maindiv {
          width: 40%;
          border: 1px solid #d6d6d6;
          height: calc(100vh - 150px);

          .maincontent {
            border: 1px solid #d6d6d6;
            padding: 0px;

            .tableContainerDiv {
              height: calc(11vh - 0px);
              display: flex;
              align-items: center;
            }

            .maincontent-heading {
              text-align: center;
              background-color: $header_background_color;
              margin-top: -3%;
              box-shadow: 2px 1px 10px 3px rgba(0, 0, 0, 0.3);
            }

            .upcomingrelease-heading {
              text-align: center;
              height: 37px;
              background-color: $header_background_color;
              box-shadow: 2px 1px 10px 3px rgba(0, 0, 0, 0.3);
            }
          }
        }

        .rightdiv {
          width: 30%;
          border: 1px solid #d6d6d6;
        }

        .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
          height: 92%;
        }

        .footer {
          display: flex;
          width: 100%;
          margin-top: 1%;

          .privacy {
            width: 10%;

            @media screen and (max-width: 767px) {
              width: 30%;
            }
          }

          .copyright {
            width: 70%;
            text-align: end;

            @media screen and (max-width: 767px) {
              margin: 2%;
            }
          }

          @media screen and (max-width: 767px) {
            margin: 2%;
            width: 98%;
          }
        }

        .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1 {
          margin: 0px;

          .MuiGrid-item {
            padding: 6px;
          }
        }

        .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-3 {
          //   max-height: 238px;
        }

        .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
          box-shadow: 4px 1px 6px 3px rgba(9, 9, 9, 88);
          background-color: transparent;
          border: 1px solid transparent;
        }
      }
    }
  }




  /* Innerheadinglandingtheme2 */


  .Innerheadinglandingtheme2 {
    font-family: $first-family;
    margin: 0.5%;

    .header {
      margin: -1%;

      @media screen and (max-width: 768px) {
        // min-height: 100px;
        // max-height: 380px;
      }

      @media screen and (max-width: 480px) {
        // min-height: 100px;
        // max-height: 380px;
      }

      .headertitle {
        display: flex;
        background-color: $primary;
        height: auto;

        .headeraction {
          display: flex;
          width: 100%;
          align-items: center;

          @media screen and (max-width: 480px) {
            -webkit-flexWrap: wrap;
            -moz-flexWrap: wrap;
          }

          @media screen and (max-width: 768px) {
            flex-direction: column;
            margin: 0px 2px;
          }

          @media screen and (max-width: 959px) {
            flex-direction: column;
            margin: 0px 2px;
          }


          .selecttheme {
            display: flex;
            text-align: center;
            align-items: center;
            width: 256px;
            color: white;
            font-size: var(--h3-font-size);
            margin-top: 8px;

            @media screen and (max-width: 768px) {
              display: block;
              text-align: left;
              padding: 1px 0px;
              width: 97.5%;
            }

            @media screen and (max-width: 480px) {
              display: block;
              width: 98%;
            }

            @media screen and (max-width: 959px) {
              display: block;
              text-align: left;
              padding: 1px 0px;
              width: 97.5%;
            }

            .statuslabel {
              // width: 45%;
              margin-right: 10px;

              @media screen and (max-width:768px) {
                margin-left: 10px;
              }

              @media screen and (max-width: 480px) {
                width: 23%;
                margin-left: 5px;
              }

              @media screen and (max-width:959px) {
                margin-left: 10px;
              }
            }

            .themelabel {
              width: 65%;

              @media screen and (max-width:768px) {
                margin-left: 5px;
              }

              @media screen and (max-width:959px) {
                margin-left: 5px;
              }
            }

            .themelabel1 {
              margin-top: 3%;
              width: 65%;

              @media screen and (max-width:768px) {
                margin-left: 10px;
              }

              @media screen and (max-width: 480px) {
                margin-top: 0%;
                width: 25% !important;
                margin-left: 5px;
              }

              @media screen and (max-width:959px) {
                margin-left: 10px;
              }
            }

            select.form-control {
              height: 39px;
              width: 100%;
              margin-left: 1%;
              border-radius: 5px;
              font-size: var(--font-text-size);

              select.form-control option {
                width: 70%;
              }

              @media screen and (max-width: 480px) {
                width: 100%;
                height: 34px;
              }

              @media screen and (max-width: 959px) {
                width: 100%;
                height: 34px;
              }
            }

            select.form-control1 {
              height: 41px;
              width: 54%;
              margin-left: 1%;
              border-radius: 5px;
              font-size: var(--font-text-size);


              @media screen and (max-width: 768px) and (min-width: 481px) {
                margin-top: 0%;
                width: 100%;
              }

              @media screen and (max-width: 480px) {
                height: 34px;
                margin-top: 0%;
                width: 100%;
              }

              @media screen and (max-width: 959px) {
                height: 34px;
                margin-top: 0%;
                width: 100%;
              }
            }
          }

          .login-form {
            display: flex;
            align-items: center;
            margin: 1% 1%;
            margin-top: 1%;
            width: 78.5%;
            font-size: var(--font-text-size);

            @media screen and (max-width: 768px) {
              margin: 5px;
              display: block;
              width: 100%;
              order: 2;
            }

            @media screen and (max-width: 480px) {
              width: 100%;
              display: block;
            }

            @media screen and (max-width: 959px) {
              width: 100%;
              display: block;
              order: 2;
            }
          }

          .sign-in-btn {
            padding: 9px 20px;
            color: white;
            width: 98%;
            margin-top: 6px;

            @media screen and (max-width: 768px) {
              padding: 12px 4px;
              display: flex;
              justify-content: flex-end;
              margin-top: -47px;
            }

            @media screen and (max-width: 959px) {
              padding: 12px 4px;
              display: flex;
              justify-content: flex-end;
              margin-top: -47px;
            }


            .logodiv {
              display: flex;
              // margin-left: -18px;
            }

            .user-name {
              margin-top: 10px;
              width: 100%;
              font-size: var(--h3-font-size);
            }

            svg.MuiSvgIcon-root {
              font-size: var(--font-text-size);
              margin-right: 8px;
              color: white;
            }

            .MuiButton-text {
              padding: 4px 8px;

              @media screen and (max-width: 768px) {
                margin-top: 0px;
              }

              @media screen and (max-width: 959px) {
                margin-top: 0px;
              }
            }
          }

          .form-group {
            display: flex;
            align-items: center;
            color: white;

            @media screen and (max-width: 768px) {
              display: block;
              margin-left: 0.5%;
            }

            @media screen and (max-width: 959px) {
              display: block;
              margin-left: 0.5%;
            }

            .MuiFormControl-marginNormal {
              padding: 1px 20px;
              margin-top: 0px;
              margin-bottom: 0px;

              @media screen and (max-width: 768px) {
                padding: 1px 0px;
              }

              @media screen and (max-width: 959px) {
                padding: 1px 0px;
              }
            }

            input#standard-password-input {
              padding: 7px 7px;
            }

            input#standard-uncontrolled {
              padding: 7px 7px;
            }
          }

          .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
            height: 31px;
            background-color: white;
          }

          button.btn-primary {
            background-color: #e63737 !important;
            font-size: 14px !important;
            color: white;
            text-transform: none;
            font-weight: bold;
            width: 100px;

            @media screen and (max-width: 768px) {
              margin-top: 17px;
              width: 150px;
            }

            @media screen and (max-width: 959px) {
              margin-top: 17px;
            }
          }

          button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit {
            color: white;


            @media screen and (max-width:768px) {
              order: 2;
              max-width: max-content;
              align-self: flex-end;
            }

            @media screen and (max-width:959px) {
              order: 2;
              max-width: max-content;
              align-self: flex-end;
            }
          }
        }
      }

      .table-header-title {
        text-align: left;
        //margin-left: 5.5%;
        margin: -16px 0px -20px 80px;

        h2 {
          font-family: $first-family;
          font-size: 32px;
          color: white;
          width: 94%;
        }

        @media screen and (max-width: 768px) {
          margin-left: 1%;
          align-items: center;

          h2 {
            font-size: 25px;
            width: 94%;

            @media screen and (max-width: 480px) {
              margin-top: 9%;
              font-size: 23px;
            }
          }
        }

        span p {
          font-family: $first-family;
          font-size: var(--font-text-size); //14px;
          color: #7d86a9;
        }

        .sub-header {
          display: flex;
          align-items: center;
          margin-left: 5px;

          .title {
            @media screen and (max-width: 480px) {
              margin-top: 30%;
            }

          }


          .logo {
            //  width: 4%;
            margin-right: 15px;

            @media screen and (max-width: 768px) {
              width: 7%;
              margin-top: 2%;
            }

            @media screen and (max-width: 959px) {
              width: 7%;
              margin-top: 2%;
            }

            @media screen and (max-width: 480px) {
              width: 13%;
              margin-top: 6%;
            }

            span.logo img {
              height: 33px;
            }
          }
        }

        .textsearch {
          width: 92%;
          margin-left: 1%;
          margin-top: -3%;
          margin-bottom: 6%;

          .ForwardRef-root-145 {
            border-radius: 10px;
            background-color: #2C5F78;
          }

          .search {
            background-color: #2C5F78;
          }

          .MuiInputBase-input {
            color: white;
          }

          .MuiIconButton-label {
            color: white;
          }

          .MuiPaper-root.jss1.search-bar.search.MuiPaper-elevation1.MuiPaper-rounded {
            background-color: #2c5f78;
            color: white;
          }

          button.MuiButtonBase-root.MuiIconButton-root.jss2.jss4 {
            color: white;
          }

          @media screen and (max-width: 1160px) and (min-width: 960px) {
            width: 88%;
            margin-bottom: 12%;
          }

          @media screen and (max-width: 959px) and (min-width: 766px) {
            width: 89%;
            margin-left: 0.5%;
            margin-bottom: 3%;
          }

          @media screen and (max-width: 768px) {
            width: 99%;
            margin-left: 0%;
            margin-top: -1%;
            margin-bottom: 6%;
          }
        }
      }

      .landingmaintheme2 {
        display: flex;
        margin: 0px 80px;
        border-radius: 10px;
        height: calc(45vh - 1px);
        justify-content: space-between;

        @media screen and (max-width: 768px) {
          display: block;
          margin: 0px 2px;
        }

        p {
          font-size: var(--font-text-size); //$landing-page-third-font-size;
          font-family: $first-family;
          color: white;
        }

        .content-heading {
          border-bottom: 1px solid #d6d6d6;
          background: $table_header_color;
          padding: 5px 0px;
          height: 22px;
          color: $font-color;
          display: flex;
          justify-content: space-between;
          text-align: center;
          align-items: center;

          span {
            font-size: var(--font-text-size); //$landing-page-third-font-size;
            font-family: $font-family;
            font-weight: 600;
            text-transform: capitalize;
          }

          strong {
            font-size: $landing-page-third-font-size;
            width: auto;
            font-family: $font-family;

            svg.MuiSvgIcon-root {
              width: 15px;
              height: 15px;
              vertical-align: sub;
            }
          }
        }

        .important-details {
          height: calc(18vh - 10px);
          border-bottom: 1px solid #d6d6d6;
          margin: 6px;
        }

        .MuiCardHeader-root {
          display: flex;
          // padding: -2px;
          // display: flex;
          align-items: center;
          border: 1px solid #ccc;
          background: $primary;
          color: #000;
          padding: 14px 14px;
          justify-content: space-between;
          // align-items: center;
        }

        .MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded {
          border: 1px solid #d6d6d6;
        }

        .MuiCardContent-root {
          @include custom-scroller("xy");
          height: 37px;
        }

        .importantcontent {
          height: 92%;
          overflow: auto;

          .tableContainerDiv {
            display: flex;
            align-items: center;
            // height: calc(8vh - 0px);
            border-bottom: 1px solid #d6d6d6;
            height: 38px;
          }

          span.icon {
            padding: 9px 14px;

            svg.MuiSvgIcon-root {
              height: 25px;
              width: 25px;
              margin-right: 5%;
              border-radius: 40%;
              background: $primary;
              color: white;
              padding: 2px 0px;
            }
          }
        }

        .table-title {
          background-color: $header_background_color !important;
          height: 40px;
          justify-content: center;

          div {
            text-align: center;
          }

          .iconclass {
            padding: 12px 12px;
          }
        }

        .content-label {
          float: left;
          margin-right: -18%;
          font-family: $font-family;
        }

        table {
          font-family: $font-family;
          border-collapse: collapse;
          width: 100%;
        }

        td,
        th {
          text-align: left;
          // padding: 8px;
        }

        tr.MuiTableRow-root.MuiTableRow-head {
          height: 33px;
          background-color: #4496be !important;
        }

        .MuiTableBody-root .MuiTableCell-root {
          padding: 2px 10px !important;
        }

        .headercontent {
          padding: 1px 0px;
          height: 50%;

          h3 {
            text-align: left;
            font-size: var(--h3-font-size);
            font-family: $first-family;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: white;
          }

          .MuiGrid-justify-xs-space-between {
            height: 40px;
            padding: 0px 19px;
          }

          .table-header {
            border-bottom: 1px solid #d6d6d6;
            margin: 0% 5%;

            .release {
              //height: 14px;
              font-family: $first-family;
              font-size: var(--font-text-size); //$landing-page-third-font-size;
              letter-spacing: normal;
              font-weight: normal;
              color: white;
            }

            .our-online-presence {
              font-family: $first-family;
              font-size: var(--font-text-size); //$landing-page-third-font-size;
              color: white;
            }

            p {
              font-size: var(--font-text-size); //$landing-page-third-font-size;
              margin-top: -3%;
              font-family: $first-family;
            }

            h3 {
              width: 100%;
              font-family: $first-family;
              font-size: var(--h3-font-size);
              color: white;
            }

            .productlisturl {
              font-family: $first-family;
              font-size: var(--font-text-size); //$landing-page-second-font-size;
              font-weight: bold;
              color: white;

              a {
                color: white;
                text-decoration: blink;
                font-family: $first-family;
              }

              span {
                cursor: pointer;
                font-size: var(--font-text-size); //$landing-page-second-font-size;
                font-weight: bold;
                color: white;
                font-family: $first-family;
              }
            }

            .productIcon {
              margin-right: 3%;
            }

            .producturl {
              font-family: $first-family;
              font-size: var(--font-text-size); //$landing-page-third-font-size;
              color: white;
              cursor: pointer;
              display: flex;
              align-items: center;
              margin-top: 2%;

              .product-document {
                margin-left: 10px;
                color: white;
              }

              svg.MuiSvgIcon-root {
                color: var(--link-color);
                margin-right: 3%;
              }
            }

            .tableContainerRoot {
              .product-listing {
                font-family: $first-family;
                font-size: $landing-page-third-font-size;
                font-weight: normal;
                color: white;
              }
            }

            .productRelease {
              width: 104%;
              overflow: auto;
              height: calc(30vh - 10px);
            }

            .suport-desk {
              width: 110px;
              font-family: $first-family;
              font-size: $landing-page-first-font-size;
              color: #000000;
            }
          }

          .table-header-large {
            border-bottom: 1px solid #d6d6d6;
            margin: 8% 5%;

            .release {
              //height: 14px;
              font-family: $first-family;
              font-size: var(--font-text-size); //$landing-page-third-font-size;
              letter-spacing: normal;
              font-weight: normal;
              color: white;
            }

            .our-online-presence {
              font-family: $first-family;
              font-size: var(--font-text-size); //$landing-page-third-font-size;
              color: white;
            }

            p {
              font-size: var(--font-text-size); //$landing-page-third-font-size;
              margin-top: -3%;
              font-family: $first-family;
            }

            h3 {
              width: 100%;
              font-family: $first-family;
              font-size: var(--h3-font-size);
              color: white;
            }

            .productlisturl {
              font-family: $first-family;
              font-size: var(--font-text-size); //$landing-page-second-font-size;
              font-weight: bold;
              color: white;

              a {
                color: white;
                text-decoration: blink;
                font-family: $first-family;
              }

              span {
                cursor: pointer;
                font-size: var(--font-text-size); //$landing-page-second-font-size;
                font-weight: bold;
                color: white;
                font-family: $first-family;
              }
            }

            .productIcon {
              margin-right: 3%;
            }

            .producturl {
              font-family: $first-family;
              font-size: var(--font-text-size); //$landing-page-third-font-size;
              color: white;
              cursor: pointer;
              display: flex;
              align-items: center;
              margin-top: 2%;

              .product-document {
                margin-left: 10px;
                color: white;
              }

              svg.MuiSvgIcon-root {
                color: var(--link-color);
                margin-right: 3%;
              }
            }

            .tableContainerRoot {
              .product-listing {
                font-family: $first-family;
                font-size: $landing-page-third-font-size;
                font-weight: normal;
                color: white;
              }
            }

            .productRelease {
              width: 104%;
              overflow: auto;
              height: calc(30vh - 10px);
            }

            .suport-desk {
              width: 110px;
              font-family: $first-family;
              font-size: $landing-page-first-font-size;
              color: #000000;
            }
          }
        }

        .maindiv {
          width: 40%;
          border: 1px solid #d6d6d6;
          height: calc(100vh - 150px);

          .maincontent {
            border: 1px solid #d6d6d6;
            padding: 0px;

            .tableContainerDiv {
              height: calc(11vh - 0px);
              display: flex;
              align-items: center;
            }

            .maincontent-heading {
              text-align: center;
              background-color: $header_background_color;
              margin-top: -3%;
              box-shadow: 2px 1px 10px 3px rgba(0, 0, 0, 0.3);
            }

            .upcomingrelease-heading {
              text-align: center;
              height: 37px;
              background-color: $header_background_color;
              box-shadow: 2px 1px 10px 3px rgba(0, 0, 0, 0.3);
            }
          }
        }

        .rightdiv {
          width: 30%;
          border: 1px solid #d6d6d6;
        }

        .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
          height: 92%;
        }

        .footer {
          display: flex;
          width: 100%;

          .privacy {
            width: 10%;

            @media screen and (max-width: 767px) {
              width: 30%;
            }
          }

          .copyright {
            width: 70%;
            text-align: end;

            @media screen and (max-width: 767px) {
              margin: 2%;
            }
          }

          @media screen and (max-width: 767px) {
            margin: 2%;
            width: 98%;
          }
        }

        .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1 {
          margin: 0px;

          .MuiGrid-item {
            padding: 6px;
          }
        }

        .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-3 {
          //  max-height: 238px;
        }

        .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
          background-color: #2C5F78;

        }
      }
    }
  }

  .account-lock {
    margin-left: 10px;
    color: red;
  }

  h6.MuiTypography-root.title.MuiTypography-h6 {
    text-align: left;
    width: 90%;
  }

  .content-box {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 94%;
    margin: 15px;
    width: 300px;
    height: 10%;

    @media screen and (max-width: 767px) {
      height: 70vh;
    }

    .MuiFormControl-marginNormal {
      width: 98%;
    }

    .prelogin-box {
      padding: 25px 20px 0px;
      border-radius: 20px;
      box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), -1px -1px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12);
      background-color: #fff;
      min-width: 350px;
      margin: 1%;
      border: 1px solid #fff;
      border-top: 1px solid #e1e1e1;

      .form {
        .form-heading {
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $primary;
          margin: 0 !important;
          margin-bottom: 30px;
          text-align: center;
        }

        .loginimg {
          text-align: center !important;

          img {
            width: 20%;
          }
        }

        .form-group {
          margin-bottom: 0px;
          position: relative;

          &.password-field {
            margin-bottom: 10px;

            .MuiFormControl-marginNormal {
              margin-bottom: 0px !important;
            }
          }

          .MuiInputLabel-animated {
            font-size: $font-text-size !important;
          }

          .MuiFormControl-root {
            width: 100%;
            font-size: $font-text-size !important;

            .MuiTextField-root.MuiFormControl-marginNormal {
              width: 90%;
              margin: 1%;
            }

            .MuiInputBase-root {
              width: 100%;
              font-size: $font-text-size !important;
            }

            svg.MuiSvgIcon-root {
              font-size: 19px;
              color: var(--link-color);
            }
          }

          input#standard-password-input {
            padding: 7px 7px;
          }

          input#standard-uncontrolled {
            padding: 7px 7px;
          }
        }

        .MuiGrid-justify-xs-space-between {
          .MuiGrid-item {
            .MuiTypography-body1 {
              font-size: $font-text-size !important;
            }

            .MuiButton-label {
              font-size: $font-text-size !important;
            }
          }
        }

        .login-button {
          text-align: center;
          margin: 0px;
          padding-top: 30px;

          .MuiButton-label {
            font-size: $font-text-size !important;

            svg.MuiSvgIcon-root {
              margin: 0px 5px;
              font-size: 17px;
            }
          }

          .submit-button {
            border-radius: 5px;
            color: $secondary !important;
            width: 100%;
            font-weight: 600;
          }

          .sign-up-cover {
            margin: $font-text-size;
            font-size: $font-text-size !important;

            .sign-up-link {
              color: $primary;
            }
          }
        }

        .login-lock {
          text-align: center;
          margin-top: -1%;
          margin-bottom: 3%;
          color: red;
        }
      }
    }
  }

  .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
    width: 98%;
  }

  .MuiGrid-root.MuiGrid-container.MuiGrid-justify-content-xs-space-between {
    padding: 0px 19px;
  }

  .without-login {
    // margin-top: 17px;
    margin-left: 34px;

    @media screen and (max-width:768px) {
      margin-left: 10px;
    }

    @media screen and (max-width:959px) {
      margin-left: 9px;
    }

    @media screen and (max-width:480px) {
      margin-left: 7px;
    }
  }

  .header-border {
    border-bottom: 1px solid var(--table_header_border_color);
  }

  .productReleaseLarge {
    width: 104%;
    overflow: auto;
    height: calc(40vh - 10px);

    @media screen and (max-width:768px) {
      height: calc(25vh - 10px);
    }
  }

  .social-account1 {
    @media screen and (max-width:768px) {
      height: calc(10vh - 10px) !important;
    }
  }

  .table-headerlarge {
    margin: 8% 5%;
  }

  .gridclass {
    width: 100% !important;

    .gridclass1 {
      height: calc(50vh - 10px);
      margin-top: -0.9%;

      .headercontent {
        padding: 1px 0px;
        height: 40% !important;
      }

      .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
        height: 100% !important;
      }

      .table-header {
        border-bottom: 1px solid #d6d6d6;
        margin: 0% 5%;

        // height: calc(10vh) !important;
        .productRelease {
          width: 100%;
          overflow: auto;
          height: calc(24vh - 3px);

          @media screen and (max-width:768px) {
            height: calc(12vh - 3px) !important;
          }

          @media screen and (max-width:480px) {
            height: calc(17vh - 3px) !important;
          }

          @media screen and (max-width:959px) {
            height: calc(12vh - 3px) !important;
          }
        }

        .social-account {
          @media screen and (max-width:768px) {
            height: calc(8vh - 3px) !important;
          }

          @media screen and (max-width:959px) {
            height: calc(8vh - 3px) !important;
          }

          @media screen and (max-width:480px) {
            height: calc(9vh - 3px) !important;
            overflow: hidden;
          }
        }
      }

    }
  }

}


.content-grid {

  @media screen and (max-width: 480px) {
    overflow-y: scroll !important;
    height: 105px;
  }

}

.footer {
  // position: fixed;
  bottom: 0;
  left: 0;
  padding: 5px 30px;

  @media screen and (max-width: 480px) {
    // max-height: 50px;
    margin: 0% !important;
    column-gap: 10px;
  }

  @media screen and (max-width : 300px) {
    padding: 5px 15px !important;
  }

  .copyright {
    @media screen and (max-width: 480px) {
      margin: 0% !important;

    }

    p {
      @media screen and (max-width: 480px) {
        margin-top: 0% !important;
        margin-bottom: 3px;
        // margin-right: 45px !important;

      }
    }
  }

  p {
    color: white !important;
    font-weight: 600;

    @media screen and (max-width: 480px) {
      margin-top: 0% !important;

    }

  }

}

.loader-landingPage {
  @media screen and (max-width: 767px) {
    position: fixed;

    // transform: translate(-50%, -50%);
    #loader-wrapper #loader {
      top: 40%;
      left: 40%;
    }
  }
}

.main-landingpage {
  background-color: var(--background--default-color);
  overflow-y: scroll;
  height: 95vh;


  .view-wrapper.view-wrapper-dashboard {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: var(--content-padding);
    padding-bottom: var(--content-padding);
    position: relative;
  }
}


.cards {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  box-sizing: border-box;
  gap: 10px;

  @media screen and (max-width : 768px) {
    display: block;
  }

  @media screen and (min-width : 768px) and (max-width: 950px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    margin-top: 10px
  }

  @media screen and (max-width : 1024px) {
    gap: 10px;
  }

  &.compact {


    .card {
      background-color: var(--side-panel-background);
      border: none;
      height: 120px;
    }
  }

  &.normal {
    margin-bottom: 100px;

    @media screen and (max-width : 767px) {
      margin-top: 0;
    }

    @media screen and (max-width : 1000px) {
      margin-bottom: 150px;
    }
  }
}