@import '../../shared/components/commonui/style/index.scss';



// Create Contract

.error-msg {
    display: flex;
    justify-content: center;
    width: 80%;

    @media screen and (max-width: 768px) {
        width: 100;
        justify-content: unset;
    }
}


.OrgIdText {
    margin-top: 10px !important;
}

// Share 
.view.data-share-list {
    width: 100%;
    display: contents;

    .view-wrapper.view-wrapper-share-list {
        background-color: var(--main-background-color);
        padding-top: var(--content-padding);
        padding-bottom: var(--content-padding);

        @media screen and (max-width :550px) {
            padding-left: 15px;
            padding-right: 15px;
          }
    }

    .dataSharing-tabs {
        &.dx-tabs {
            border-bottom: 1px solid var(--tabs-bottom-color);
            background-color: var(--tabs-background-color);
            padding: 0px 14px;

            &.dx-tabs-expanded {
                display: flex;
                justify-content: flex-start;
            }

            .dx-tabs-scrollable .dx-tabs-wrapper {
                display: flex;
            }

            .dx-tab {
                background-color: var(--tabs-background-color);
                min-width: 180px;
                max-width: 100%;

                @media screen and (max-width:768px) {
                    min-width: auto;
                }
            }
        }
    }
}