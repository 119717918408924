@import "../../../../../assets/sass/styles.scss";
:root {
  --font-text-size: 12px;
}
.useraddpopup {
  .MuiFormControl-marginNormal {
    .MuiInputBase-formControl {
      font-size: 14px;
      padding: 4px 4px;
      font-weight: 600;
    }
  }
}

.multi {
  .Role-multi-select {
    .MuiInputBase-root {
      border: 1px solid #78797a;
      border-radius: 4px;
      height: 30px;
      position: relative;
    }
    .MuiChip-root {
      padding: 0px;
      margin: 1px;
    }
    .Role-multi-chip {
      .MuiChip-root {
        padding: 0px;
        margin: 1px;
        height: 24px;
      }
      .MuiChip-deleteIcon {
        width: 13px;
      }
      span.MuiChip-label {
        font-size: 15px !important;
      }
    }
  }
}
.MuiDialogActions-spacing {
  .MuiButton-containedPrimary {
    padding: 3px 0px;
    span.MuiButton-label {
      font-weight: 800;
      font-size: var(--font-text-size);
    }
  }
}

.Role-multi-select {
  .MuiPaper-root {
    .MuiMenu-list {
    }
  }
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected {
  color: $primary;
  :hover {
     background-color: rgb(183, 183, 183) !important;
  }
}

.MuiListItem-button:hover {
   background-color: rgb(183, 183, 183) !important;
}
li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  // color: $primary;
  color: $font-color;
  padding: 0px 8px !important;
}
.rolecheck {
  svg.MuiSvgIcon-root {
    font-size: var(--font-text-size);
  }
}
.rolechecktext {
  .MuiTypography-body1 {
    font-size:var(--font-text-size) !important;
  }
}
.roleOptions {
  position: absolute;
  top: 8%;
}
