@import '../style/index.scss';


.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #0078d4;


    .footer {
        display: flex;
        width: 100%;
        background: #0078d4;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 5px 30px;
        column-gap: 6px;

        @media screen and (max-width : 500px) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            padding-left: 10px;
            text-align: center;
            width: 98%;
        }

        p {
            color: white;
            font-weight: 600;
        }

        .privacy {
            width: 18%;

            @media screen and (max-width: 767px) {
                width: 35%;
            }

            @media screen and (max-width : 500px) {
                width: 94%;
                padding-top: 10px;
            }
        }

        .copyright {

            span {
                @media screen and (max-width : 500px) {
                    text-align: end;
                }
            }

            .copyRightText {
                margin-right: 60px;

                @media screen and (max-width : 500px) {
                    margin-right: 0px;
                }
            }

            width: 70%;
            text-align: end;

            @media screen and (max-width: 767px) {
                margin: 2%;
            }

            @media screen and (max-width : 500px) {
                grid-column: 1 / -1;
                width: 91%;
            }
        }

    }
}


.appFooter {
    display: block;
    // height: 60px;
    background-color: #dcdcdc;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 0px 14px 0px;
    box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.2);

    .footer {
        display: flex;
        justify-content: space-between;

        .footerLeft {
            display: flex;

            .policy {
                font-size: 14px;
                font-weight: 500;
                color: #0078d4;

                @media screen and (min-width :2000px) {
                    font-size: 20px;
                }
            }

            .term {
                font-size: 14px;
                font-weight: 500;
                color: #0078d4;

                @media screen and (min-width :2000px) {
                    font-size: 20px;
                }
            }

            .contacts {
                font-size: 14px;
                font-weight: 500;
                color: #0078d4;

                @media screen and (min-width :2000px) {
                    font-size: 20px;
                }
            }

            .separator {
                height: 16px;
                width: 1px;
                background: #0078d4;
                margin: 0px 12px;

                @media screen and (min-width : 2000px) {
                    height: 25px;
                    width: 2px;
                }
            }
        }

        .copyright {
            font-size: 14px;
            line-height: 1.07;
            color: #495057;

            @media screen and (min-width :2000px) {
                font-size: 20px;
            }
        }
    }
}