// @import "../../../../../assets/themes/default/share.scss";
// @import "../../../../../assets/sass/styles.scss";


// .dx-dropdowneditor-overlay .dx-list-item-content {
//     line-height: 7px !important;
// }

// .dx-texteditor.dx-editor-filled::after {
//     border: none !important;
// }

// .dx-tagbox.dx-editor-filled .dx-tag-container {

// }

// .dx-texteditor.dx-state-focused::before {
//     border-bottom: unset;
// }

// .dx-tagbox .dx-texteditor-input {
//     height: 25px;
// }
// .multiSelect {
//     .dx-texteditor.dx-editor-filled {
//         background-color: rgb(255, 255, 255);

//         .dx-dropdowneditor-input-wrapper {
//             border: 1px solid rgb(204, 204, 204);
//             border-radius: 4px;
//             .dx-placeholder {
//                 font-size: 12px;
//                 color: rgb(128, 128, 128);
//                display: flex;
//                 align-items: center;
//             }
//         }


//         .dx-tag {
//             margin: 2px;
//         }

//         .dx-tag-content {
//             border-radius: 2px;
//             color: rgb(51, 51, 51);
//             padding: 3px 32px 3px 7px;
//             text-align: start;
//             text-overflow: ellipsis;
//             font-size: 85%;
//             white-space: nowrap;
//             // min-width: 120px;
//             box-sizing: border-box;

//             .dx-tag-remove-button::before {
//                 border-radius: unset;
//                 background-color: unset;
//                 color: unset;
//             }
//         }
//     }

//     .dx-show-clear-button .dx-icon-clear {
//         background-color: unset;
//         fill: currentColor;
//         font-size: 15px;
//         font-weight: bold;
//     }

//     .dx-tagbox.dx-editor-filled .dx-tag-container {
//         padding: 0px 3px 3px 3px !important;
//         min-height: 38px !important;
//     }

//     .dx-dropdowneditor-overlay .dx-list-item-content {
//         // line-height: 5px !important;
//         .dx-list-item-content {
//             padding: 0px !important;
//         }
//     }
// }

.common-multi-select {
    .dx-texteditor.dx-editor-outlined {
        border: none;
    }

    .dx-tagbox.dx-editor-outlined .dx-tag-container {
        min-height: 30px;
        padding: 0px 6px 4px 6px;
    }

    .dx-tag-content {
        min-height: 0px;
        border-radius: 4px;
        padding: 3px 32px 3px 8px;
    }

    .dx-tag-remove-button {
        &::before {
            color: black;
            background-color: unset;
        }

        &::after {
            background-color: unset;
        }
    }

    .dx-show-clear-button .dx-icon-clear {
        background-color: unset;
        margin-top: -15px;
    }


}