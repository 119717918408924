@use "../../../../variables" as *;

@include separator();

.change-profile-password-popup.form-popup {
  .dx-form {
    height: var(--change-password-popup-height, auto);

    .dx-layout-manager {
      .dx-field-item {
        padding-bottom: 8px;
        font-size: 14px;

        .dx-field-item-label-location-top {
          padding: 0px;
          margin-bottom: 4px;
        }

        .dx-field-item-content-location-bottom {
          .dx-texteditor-container {
            .dx-texteditor-buttons-container {
              .dx-button-mode-text {
                .dx-button-content {
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }

      .dx-texteditor {
        .dx-texteditor-input {
          border-bottom-color: unset;
        }

        &.dx-editor-filled {
          &::after {
            border-bottom: unset;
          }

          .dx-texteditor-input {
            padding: 5px 12px;
          }

          .dx-placeholder {
            font-size: 14px;

            &::before {
              padding: 5px 12px;
            }

            &::after {
              padding: 5px 12px;
            }
          }
        }
      }
    }
  }

  .dx-popup-normal {
    border-radius: 8px;
    padding: 8px 0;

    .dx-toolbar {
      padding: 0 24px 8px;

      .dx-toolbar-items-container {
        height: 48px;
      }
    }

    .dx-popup-content {
      padding: 16px 24px;
      height: 249px;
    }
  }
}

// .dx-popup-title.dx-toolbar .dx-toolbar-items-container {
//   height: 48px;
// }

// .dx-texteditor.dx-editor-filled::after {
//   border-bottom-color: none;
// }