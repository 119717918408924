@import '../style/index.scss';
@import "../../../../../assets/themes/default/share.scss";
@import "../../../../../assets/themes/default/style.scss";


.sharing-table {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    max-height: calc(93vh - 170px);
    padding: 10px 14px;
}


.sharing-table {
    &>.dx-datagrid {
        min-height: 100%;

        &>.dx-datagrid-headers {
            background-color: var(--table-body-header);
            box-shadow: 0 0 10px 0 rgba(67, 89, 113, 0.15);
            border-left: 0px;
            border-right: 0px;
            border-radius: 8px 8px 0px 0px;
            margin-bottom: 2px;

            .dx-datagrid-table .dx-row>td {
                border-bottom: 1px solid var(--table-header-border-bottom);
            }
        }

        &.dx-datagrid-borders>.dx-datagrid-headers {
            border-top: 0px;
        }
    }

    .dx-datagrid {
        .dx-datagrid-rowsview {
            background-color: var(--table-body-row);
            box-shadow: 0 0 10px 0 rgba(67, 89, 113, 0.15);

            &.dx-last-row-border {
                border-radius: 0px 0px 8px 8px;
            }
        }

        &.dx-datagrid-borders>.dx-datagrid-rowsview {
            border-top: 0px;
            border-left: 0px;
            border-right: 0px;
        }

        .dx-row-lines>td {
            border-bottom: 1px solid #fff;
            font-size: var(--font-text-size);
        }
    }

    .table-sorts {
        display: flex;

        .sort-icon {
            margin-left: 8px;

            .arrowicons {
                max-height: 14px;
                color: #0078d4;

                &:hover {
                    color: #0078d4;
                }
            }
        }
    }

    // ::-webkit-scrollbar {
    //     width: 10px;
    //     height: 10px;
    // }

    // ::-webkit-scrollbar-track {
    //     background-color: #f5f5f5;
    //     border-radius: 5px;
    // }

    // ::-webkit-scrollbar-thumb {
    //     background-color: #0078d4;
    //     border-radius: 5px;
    // }

    // ::-webkit-scrollbar-thumb:hover {
    //     background-color: #0078d4;
    // }

    // ::-webkit-scrollbar-button {
    //     background-color: #f5f5f5;
    //     border-radius: 5px;
    // }

    // ::-webkit-scrollbar-button:hover {
    //     background-color: #ddd;
    // }

    // ::-webkit-scrollbar-corner {
    //     background-color: #f5f5f5;
    //     border-radius: 5px;
    // }

    // ::-webkit-resizer {
    //     background-color: #f5f5f5;
    //     border-radius: 5px;
    // }
}

.share-actionBtn {
    .org-btn-group {
        display: flex;
        align-items: center;

        .dx-button {
            background-color: #0078d4;
            font-size: 12px;
            cursor: pointer;
            border: none;
            margin-right: 8px;
        }
    }

    .contract-org {
        display: flex;
        align-items: center;


        .dx-button {
            background-color: #0078d4;
            font-size: 12px;
            cursor: pointer;
            border: none;
            margin-right: 8px;
        }

    }

    .edit-org {
        min-width: 35px;
        min-height: 35px;
    }
}