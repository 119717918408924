.panel {
    
    max-height: calc((100vh - #{$header_height} - 20px)/2);
    @include custom-scroller('y');
    margin-bottom: 10px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    padding: 10px 16px;
    display: block ;
    padding-bottom: 2px;
    @media screen {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.items-container{
    width: 100%;

    .items-box{
        box-shadow: none;
        .heading{
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
            margin: 0px;
            margin-left: 4px;
            margin-right: 4px;
            font-size: 16px;
            color: #2b4d7c;
            position: relative;
            .btn-add {
                color: #fff;
                background: $primary;
                width: 24px;
                height: 24px;
                position: absolute;
                bottom: 8px;
                right: 2px;
                min-height: auto;
                
                .add {
                    font-size: 18px;
                }
            }
        }
    }

    .no-data-found {
        justify-content: center;
        height: 100%;
        flex-direction: column;
        width: 100%;
        img {
            max-height: 110px;
            display: block;
            margin: auto;
        }
    }

    .paper-card {
        width: 100px;
        cursor: pointer;
        text-align: center;
        background: $primary;
        color: #fff;
        padding: 2px 6px;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        overflow: hidden;
        font-size: $font-text-size;
    }
}
