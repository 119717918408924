.dx-texteditor.form-editor [role="button"].dx-button.form-editor-icon:first-child {
  margin-left: 0;
  pointer-events: none;
  border-color: transparent;
  background-color: transparent;
}


.form-textbox-container {
  display: flex;
  flex-direction: column;

  .form-textbox-label {
    margin-bottom: 4px;
    font-size: 14px;
  }

  .form-textbox-wrapper {
    position: relative;

    .dx-tagbox {
      &.dx-editor-outlined {
        .dx-tag-container {
          padding: 9px 10px;
        }

        .dx-placeholder {
          &::before {
            padding: 9px 10px;
          }

          &::after {
            padding: 9px 10px;
          }
        }
      }
    }
  }
}