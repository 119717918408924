.profile-icon-container{
    height: fit-content;
    width: fit-content;
    padding:12px;
    border-radius: 50%;
    background-color: transparent;

    .profile-icon-circle{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        background-color: white;
        border: 2px solid;
        color: blue; 
        cursor: pointer;

    }
}