@import '../../../../../assets/themes/default/share.scss';
@import '../style/index.scss';

.dx-icon-MyIcon1 {
    background: url('../../../../../assets/images/light.svg');
    background-size: cover;
}

.dx-icon-MyIcon2 {
    background: url('../../../../../assets/images/dark.svg');
    background-size: cover;
}

.dx-icon-MyIcon3 {
    background: url('../../../../../assets/images/material.blue.light.svg');
    background-size: cover;
}

.dx-icon-MyIcon4 {
    background: url('../../../../../assets/images/material.blue.dark.svg');
    background-size: cover;
}

.dx-icon-MyIcon5 {
    background: url('../../../../../assets/images/material.blue.light.compact.svg');
    background-size: cover;
}

.dx-icon-MyIcon6 {
    background: url('../../../../../assets/images/material.blue.dark.compact.svg');
    background-size: cover;
}


.mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width : 500px) and (max-width : 550px) {
        margin-top: 4px;
    }

    // width: 256px;

    // @media screen and (max-width: 959px) {
    //     display: block;
    //     width: 98%;
    // }

    // @media screen and (max-width : 530px) {
    //     margin-top: 44px;
    // }

    // .dropdownSelect {
    .dx-dropdownbutton {
        // background-color: #fff;
        width: 200px !important;

        @media screen and (min-width : 500px) and (max-width : 550px) {
            width: 155px !important;
        }

        @media screen and (min-width :2000px) {
            height: 48px;
        }
        .dx-button-text{
            @media screen and (min-width :2000px) {
                font-size: 22px;
            }
        }
    }

    // }

    .theme-text {
        color: $white-color;
        font-size: $font-text-size;
        font-weight: $font-weight;
        margin-right: 10px;

        @media screen and (min-width : 2000px) {
            font-size: 27px;
        }
    }
}