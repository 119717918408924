@import "../../../../assets/sass/styles.scss";

.header-page {
  display: flex;
  flex-grow: 1;
  align-content: center;
  min-height: 45px;

  @media screen and (max-width:538px) {
    height: 120px;
  }

  .title {
    flex-grow: 1;
    font-weight: 400 !important;
    line-height: 1.6 !important;
    margin-top: 14px;
  }

  .toolbar {
    background-color: #0078d4;
    padding: 0px 11px;

    // .dx-toolbar-item {
    //   padding: 0px 5px 0px 0px;
    // }

    .dx-icon {
      color: #ffffff;
    }

    h2 {
      color: #ffffff;
      font-size: 16px;
    }


    .walacorLogo {
      .dx-toolbar-item-content {
        margin-top: -6.5px;
      }
    }
  }

  span.logo img {
    height: 33px;
    margin-right: 15px;
    margin-bottom: 9px;
  }

  .dx-toolbar {
    .dx-toolbar-items-container {
      height: 56px;
      display: flex;
      flex-flow: wrap;
      width: 100%;
    }
  }

  .dx-toolbar-after,
  .dx-toolbar-after {
    @media (max-width:538px) {
      position: inherit;
    }
  }

  .dx-toolbar-after {
    @media screen and (max-width:538px) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      // height: 164px;
      top: 57px;
      left: -4px;

    }
  }

  .dx-button-mode-contained {
    background-color: transparent;
    border-color: transparent;
  }


  .username {
    height: 25px;
    width: 25px;
    border-radius: 30px;
    border: 1px solid;
    background-color: white;
    color: blue;

    div {
      align-items: center;
      font-size: 13px;
      margin-top: 4px;
    }
  }

  span.header-logo img {
    height: 33px;
    margin-right: 15px;
    margin-bottom: 2px;

    @media screen and (max-width : 450px) {
      height: 25px;
    }
  }

  .MuiAppBar-colorPrimary {
    color: $secondary;
    background-color: $primary;

    @media screen and (max-width: 767px) {
      padding-left: 0px;
      padding-right: 0px;
    }

    .MuiToolbar-gutters {
      padding: 0 11px;
      min-height: 45px;
    }

    img {
      // width: 16%;
      margin-right: 15px;

      @media screen and (max-width: 767px) {
        margin-left: 10px;
      }
    }
  }
}

div[class^="MuiPaper-root MuiMenu-paper MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded"] {
  background-color: $secondary;
  width: 11%;

  @media screen and (max-width: 767px) {
    width: 33%;
  }

  .MuiList-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    .MuiMenuItem-root {
      padding-top: 0px;
      padding-bottom: 0px;

      .MuiListItemIcon-root {
        min-width: 30px;

        svg.MuiSvgIcon-root {
          font-size: 20px;
          color: $primary;
        }
      }

      .MuiListItemText-root {
        span {
          font-size: 14px;
          color: $primary;
        }
      }
    }
  }
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  margin-top: 32px;
  width: 15%;

  @media screen and (max-width: 900px) {
    width: 200px;
  }
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}

tr.MuiTableRow-root.MuiTableRow-head {
  background: #ccc;

  th.MuiTableCell-root.MuiTableCell-head {
    font-weight: 600;
  }
}