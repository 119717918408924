@import "../../app.config.scss";
@import "../../assets/sass/global-import";
:root {
  --background-color:#fff;
}
.main-view {
  margin: 0px;
  margin-bottom: 0px;
  height: calc(100vh - 0px);
  overflow: hidden;
  .main-content {
    background: var(--background-color);
    display: flex;
    .dashboard-cover {
      width: calc(100% - #{$sidebar_width});

      &.hide-sidebar {
        width: calc(100% - 40px);
        @media screen and (max-width: 767px) {
          width: 100%;
          margin-left: 1%;
          margin-top: 14%;
        }
        @media screen and (max-width: 1024px) and (min-width: 768px) {
          width: 100%;
          margin-left: 2%;
          margin-top: 6%;
        }
        @media screen and (max-width: 1300px) and (min-width: 1024px) {
          width: 100%;
          margin-left: 2%;
          margin-top: 5%;
        }
      }
    }
  }
}

input,
strong,
label,
span,
div,
textarea,
button,
body,
li,
a,
b,
hr,
br,
h1,
h2,
h3,
h4,
h5,
h6,
legend,
nav,
option,
p,
select,
table,
td,
tr,
tbody,
thead {
  font-family: $font-family;
}
