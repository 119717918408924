@import "../../../assets/themes/default/share.scss";

body {
  margin: 0px;
  padding: 0px;

  .content-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    @media screen and (max-width: 767px) {
      height: 70vh;
    }
    .login-header {
      .MuiToolbar-regular {
        min-height: 45px !important;
        .MuiIconButton-root {
          padding: 10px;
          .MuiIconButton-label {
            svg.MuiSvgIcon-root {
              background: #044c92;
              border-radius: 100%;
              color: #fff;
            }
          }
        }
      }
      .appBar-close .MuiToolbar-gutters {
        padding-left: 20px !important;
      }
    }
  }
}

.MuiAppBar-colorPrimary:first-child {
  background: $primary;
  color: #fff;
  border: none;
}
