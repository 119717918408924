.items-list {
  max-height: 250px;
  @include custom-scroller("y");
  .item {
    display: inline-block;
    width: auto;
    padding: 6px 7px;
    @media screen and (max-width: 767px) {
      padding: 6px 16px;
    }
    .avatar-img-cover {
      min-width: 35px;

      .currency-img {
        width: 35px;
        height: 35px;
        box-shadow: 1px 2px 4px rgba(43, 77, 124, 0.34);
        border-radius: 2px;
        background-color: white;
        &.no-imgShowing {
          background-color: #2b4d7c;
        }

        .MuiAvatar-img {
          font-size: $font-text-size;
        }
      }
    }

    .btn-primary {
      width: 110px;
      cursor: pointer;
      text-align: center;
      color: white;
      padding: 2px 6px;
      display: block;
      font-size: $font-text-size;
      background-color: $primary;

      .MuiButton-label {
        text-transform: capitalize;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        overflow: hidden;
      }
    }

    &.active {
      .btn-primary {
        background: $primary;
      }
    }
  }
}

// Tooltip-styling
.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    font-size: 11px;
    background-color: rgba(97, 97, 97, 4.5);
  }
}
