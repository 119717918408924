@mixin custom-scroller($axis, $brodness: 4px, $brodness_new: 6px, $bgcolor: #0078d4) {
  @if ($axis == "y") {
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: $brodness;
    }
    &::-webkit-scrollbar-track {
      width: $brodness;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 20px 20px;
      background-color: $bgcolor;
      outline: 1px solid slategrey;
      opacity: 0.5;
      width: $brodness;
      &:hover {
        opacity: 1;
      }
    }
  }
  // overflow x-axis
  @if ($axis == "x") {
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      height: $brodness;
    }
    &::-webkit-scrollbar-track {
      height: $brodness;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 20px 20px;
      background-color: $bgcolor;
      outline: 1px solid slategrey;
      height: $brodness;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
  @if ($axis == "xy") {
    overflow-x: auto;
    overflow-y: auto;
    &::-webkit-scrollbar {
      height: $brodness_new;
      width: $brodness_new;
    }
    &::-webkit-scrollbar-track {
      height: $brodness_new;
      width: $brodness_new;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 20px 20px;
      background-color: $bgcolor;
      outline: 1px solid slategrey;
      height: $brodness_new;
      width: $brodness_new;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
}

@mixin scroller($brodness: 6px, $bgcolor: #0078d4) {
  overflow: auto;
  &::-webkit-scrollbar {
    width: $brodness;
    height: $brodness;
  }
  &::-webkit-scrollbar-track {
    width: $brodness;
    height: $brodness;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px 20px;
    background-color: $bgcolor;
    outline: 1px solid slategrey;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}
