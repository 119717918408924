@use "../../../variables.scss" as *;
@import "../../../assets/themes/default/share.scss";
@import "../../../assets/sass/global-import";

// .paging-tab {
//   margin-bottom: 10px !important;
//   margin-top: 10px !important;

//   .tab-color {
//     &.dx-tabs-expanded {
//       display: flex;
//       justify-content: center;
//     }

//     .dx-tabs-wrapper {
//       .dx-item-content {
//         // color: #000;
//         text-transform: capitalize;
//       }
//     }
//   }
// }

.view-wrapper {
  &.view-wrapper-list-details {
    position: relative;
    display: flex;
    padding-top: var(--content-padding);
    padding-bottom: var(--content-padding);

    @media screen and (max-width :550px) {
      padding-left: 15px;
      padding-right: 15px;
    }
    
  }

  .lists {
    font-size: 20px;
    font-weight: bold;
    line-height: 0.75;
    letter-spacing: 0.33px;
    color: #0078d4;
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  .dx-card {
    border-radius: 15px;
  }

  // .tabsList {
  //   &.dx-tabpanel {
  //     .dx-tabs-wrapper {
  //       display: flex;
  //     }

  //     .dx-tabs {
  //       background-color: var(--tabs-background-color);
  //       border-bottom: 1px solid var(--tabs-bottom-color);
  //       padding: 0px 14px;


  //       .dx-tab {
  //         background-color: var(--tabs-background-color);
  //         min-width: 180px;

  //         @media screen and (max-width:768px) {
  //           min-width: auto;
  //         }
  //       }
  //     }
  //   }
  // }

  .tabFileList {
    &.dx-tabs {
      border-bottom: 1px solid var(--tabs-bottom-color);
      background-color: var(--tabs-background-color);
      padding: 0px 14px;

      &.dx-tabs-expanded {
        display: flex;
        justify-content: flex-start;
      }

      .dx-tabs-scrollable .dx-tabs-wrapper {
        display: flex;
      }

      .dx-tab {
        background-color: var(--tabs-background-color);
        min-width: 180px;

        @media screen and (max-width:768px) {
          min-width: auto;
        }
      }
    }
  }


}