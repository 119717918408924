:root {
    --url-color: #000;
    --dark-background-color1: #363640;
    --react-google-chart-background: #fff;
    --header-title-color: #0078d4;
    --font-color-default: rgba(0, 0, 0, .87);
    --background-color-default: #fff;
    --border-color-default: rgba(0, 0, 0, 0.12);
    --icon-color-default: rgba(0, 0, 0, 0.54);
    --font-size-btn: 12px;
    --content-padding: 24px;
    --toolbar-height:48px;
    --page-padding: 32px;
    --font-text-date: 15px;
    --tabs-background-color: #fff;
    --tabs-bottom-color: rgb(97, 97, 97);
    --main-background-color: rgb(250, 250, 250);
    --table-header-background: #EFF2F7;
    --table-body-background: #FBFCFF;
    --card-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06);
    --main-bgColor: #fff;
    --sub-table-background: #f0f4fa;
    --table-color:#495057;
    --drawer-text-color:#222222;
    --table-body-header:#e8ebf0;
    --table-body-row:#eef0f4;
    --nested--table-header: #dbe1ea;
    --table-body-border-bottom : #fff;
    --table-header-border-bottom : #fff;
    --select-box-border-color : #979797;
    --select-shcema-background-color : #efefef;
    --active-sidebar-color: #ef5777;
    --active-sidebar-backgroundColor : #f4f2f0;
}