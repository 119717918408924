@import '../style/index.scss';
@import "../../../../../assets/themes/default/share.scss";
@import "../../../../../assets/themes/default/style.scss";

.pagination-covers {
    margin-top: 5px;
    box-shadow: 1px 1px 11px 3px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 4px 6px;

    .total-result {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .total-size {
            display: flex;
            align-items: center;
            gap: 10px;

            .filter-box {
                .option {
                    .page-size {

                        .dx-texteditor,
                        .dx-editor-outlined,
                        .dx-texteditor-input {
                            padding: 6px 14px;
                            width: 100px;
                        }
                    }
                }
            }
        }

        .pagination-container {
            display: flex;
            align-items: center;
            justify-content: center;
            // margin: 20px 0;

            .pagination-item,
            .pagination-prev,
            .pagination-next {
                border: 1px solid #d8dbe0;
                background: #fff;
                cursor: pointer;
                outline: none;
                padding: 4px 12px;
                margin: 0 5px;
                font-size: 15px;
                border-radius: 2px;
                font-family: $font-family-sans-serif;

                &-disabled {
                    padding: 4px 12px;
                    margin: 0 5px;
                    font-size: 15px;
                    border-radius: 2px;
                    border: 1px solid #d8dbe0;
                    ;
                    font-family: $font-family-sans-serif;
                }

                &.pagination-prev,
                &.pagination-next {
                    color: #000;
                    border: 1px solid #d8dbe0;
                    ;
                    font-family: $font-family-sans-serif;

                    &:disabled {
                        background-color: white;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
}