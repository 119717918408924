:root {
    --font-text-size: 12px;
    // --background-color: #fff;
    --color: #0078d4;
    --border_color: #ccc;
    --font-color: #000000;
}

.SubHeaders {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 15px;
    flex-wrap: wrap;
    row-gap: 10px;

    // @media screen and (max-width: 767px) {
    //     flex-direction: column;
    //     justify-content: flex-start;
    // }


    .subheader-title {
        .title {
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 0.75;
            letter-spacing: 0.33px;
            color: #0078d4;

            @media screen and (max-width: 767px) {
                font-size: 14px;
                font-weight: 600;
            }
        }
    }



    .sub-header-search {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        row-gap: 10px;

        .search-header {
            margin: 0px 10px;
            box-shadow: 0 1px 1px rgba(0, 0, 0, .12), 0 1px 1px rgba(0, 0, 0, .24);

            @media screen and (max-width: 480px) {
                margin: 0px 0px 0px;
            }

            &.dx-texteditor {
                &.dx-editor-outlined {
                    .dx-texteditor-input {
                        padding: 0px 16px;
                    }

                    .dx-placeholder {
                        &::before {
                            padding: 8px 16px;
                        }

                        &::after {
                            padding: 8px 16px;
                        }
                    }
                }
            }
        }

        .sub-header-buttons {
            // @media screen and (max-width: 767px) {
            //     margin-top: 5px;
            // }

            .dx-button {
                color: #fff;
                background-color: #0078d4;
                // margin: 0px 4px;

                &[aria-label="Import"]{
                    margin: 0px 0 0 8px;
                }
            }
        }
    }



    .user-filter {
        margin: 2px 25px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        @media screen and (max-width: 767px) {
            margin: 3px;
            width: 100%;
        }

        .statuslabel {
            margin-right: 7px;
            font-size: var(--font-text-size);
            font-weight: bold;
            margin-bottom: 3%;
        }

        .filter-ddl {
            height: 19px;
        }

        select.form-control {
            // color: var(--font-color);
            align-items: center;
            // background-color: var(--background-color);
            cursor: default;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            // min-height: 29px;
            position: relative;
            box-sizing: border-box;
            border-color: var(--border_color);
            border-radius: 5px;
            border-style: solid;
            border-width: 1px;
            transition: all 100ms ease 0s;
            outline: 0px !important;
            margin-top: -15%;
            height: 35px;
            padding: 0px 7px;
            font-size: var(--font-text-size);
        }
    }

    .ForwardRef-root-132 {
        height: 35px;
    }
}