.sub-landingpage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;

    .sub-header-landing {
        margin-right: 10px;

        .title {
            font-size: 24px;
            font-weight: bold;
            line-height: 0.63;
            letter-spacing: 0.3px;
            color: #0078d4;
        }
    }

    .text-search {
        width: 350px;

        .search-header {
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
            border: 1px solid var(--select-box-border-color);
        }
        .dx-texteditor {
            &.dx-editor-outlined {
                .dx-texteditor-input {
                    padding: 10px 12px 10px;
                    min-height: 30px;
                }

                .dx-placeholder {
                    font-size: 12px;

                    &::before {
                        padding: 10px 12px 10px;
                    }

                    &::after {
                        padding: 10px 12px 10px;
                    }
                }
            }
        }
    }

    // .table-header-title {
    //     margin: 0px 80px;

    //     .header-title {
    //         font-size: 32px;
    //         color: #434b52;
    //         margin: 50px 0px 10px 0px;
    //     }

    //     .searchBox {
    //         display: flex;
    //         width: 100%;
    //         margin-bottom: 8px;
    //     }
    // }
}