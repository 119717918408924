.product-document {
    border-radius: 0.625rem;
    box-shadow: var(--card-shadow);
    background-color: var(--background-color-default);
    border: none;
    // padding: 1.875rem 2.437rem 1.875rem 1.875rem;
    padding: 1.25rem;
    width: 100%;
    overflow: hidden;

    @media screen and (max-width : 768px) {
        width: unset;
        margin-bottom: 10px
    }

    @media screen and (min-width : 768px) and (max-width: 950px) {
        gap: 20px;
        width: 90%;
    }

    @media only screen and (min-width: 1024px) {
        width: calc(23% - 3px);
    }

    .title {
        font-size: 1.5em;
        color: var(--url-color);
        font-weight: 500;
        margin-bottom: 1.875rem;

        @media screen and (min-width : 2000px) {
            font-size: 2.2em;
        }
    }

    .middle {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            .icon-wrapper {
                background-color: #e5f1fa;
                width: 5.9vw;
                height: 5.9vw;
                border-radius: 3.4vw;
                display: flex;
                justify-content: center;
                align-items: center;

                @media screen and (max-width : 950px) {
                    width: 70px;
                    height: 70px;
                    border-radius: 45px;
                }

                svg {
                    @media screen and (max-width : 950px) {
                        width: 45px;
                        height: 45px
                    }
                }
            }

            .product-title {
                font-size: 1.125em;
                color: var(--font-color-default);
                font-weight: 500;
                // width: 10.4vw;
                text-align: center;
                margin-top: 0.625rem;
                padding-right: 0.625rem;

                @media screen and (min-width : 2000px) {
                    font-size: 1.8em;
                }
            }
        }
    }
}