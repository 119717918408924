.prelogin-box {
  min-width: 360px !important;

  @media screen and (max-width: 767px) {
    min-width: 300px !important;
  }

  .login-button {
    text-align: center;
    margin: 0px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
}
