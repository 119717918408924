@import '../../../../../assets/themes/default/share.scss';
@import '../style/index.scss';

.InnerHeaderLanding {
    .header {
        .header-titles {
            background-color: $primary;
            padding: 0px 11px;

            @media screen and (max-width: 959px) {
                padding: 6px 10px;
            }

            .header-section {
                display: flex;
                // width: 100%;
                flex: 1;
                align-items: center;

                .with-login {
                    span.logos img {
                        width: 118px;
                        height: 49px;
                        object-fit: contain;

                        @media screen and (max-width :530px) {
                            height: 25px;
                        }

                        @media screen and (min-width : 2000px) {
                            width: 130px;
                            height: 80px;
                        }
                    }

                    @media screen and (max-width: 959px) {
                        width: 100%;
                    }
                }

                @media screen and (max-width: 959px) {
                    flex-direction: column;
                }

                .sign-btn {
                    margin: 0px 0px 0px 14px;
                    width: 98%;
                    color: $white-color;

                    .dx-button-mode-contained {
                        border-color: $red-color;

                        .dx-button-text {
                            font-weight: $font-weight-700;
                            text-transform: none;
                        }
                    }

                    .dx-icon {
                        color: $white-color;
                    }

                    @media screen and (max-width: 959px) {
                        padding: 12px 4px;
                        display: flex;
                        justify-content: flex-end;
                        margin-top: -35px;
                        margin-left: 0%;
                    }

                    .logodiv {
                        display: flex;
                    }

                    .user-name {
                        align-items: center;
                        width: 100%;
                        font-size: 16px;
                        margin-left: 10px;
                        font-weight: bold;

                        @media screen and (max-width: 530px) {
                            font-size: 15px;
                        }

                        @media screen and (min-width : 2000px) {
                            font-size: 27px;
                        }
                    }
                }

                .loginForm {
                    display: flex;
                    align-items: center;
                    margin: 0px 0px 0px 16px;
                    flex: 1;
                    font-size: var(--h3-font-size);

                    @media screen and (max-width: 959px) {
                        display: block;
                        width: 100%;
                        order: 2;
                    }

                    .username-input-box {
                        display: flex;
                        align-items: center;

                        // color: white;
                        .dx-button-content {
                            padding: 6px 25px;
                        }

                        @media screen and (max-width: 959px) {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;

                            // .dx-button-content {
                            //   padding: 6px 25px;
                            // }
                        }

                        .label {
                            padding: 0px 10px;
                            color: $white-color;
                            font-size: $font-text-size;

                            @media screen and (max-width: 959px) {
                                padding: 5px 5px 0px 0px;
                            }
                        }

                        .dx-texteditor {
                            .dx-texteditor-container {
                                height: 36px;
                            }
                        }

                        .dx-texteditor.dx-editor-outlined {
                            border: unset;

                            @media screen and (max-width: 959px) {
                                width: 96%;
                            }

                            .dx-texteditor-input {
                                padding: 8px 8px;
                                // color: #000;

                            }

                            .dx-placeholder::before {
                                padding: 8px 8px;
                            }
                        }
                    }

                    .back-button-signInButton {
                        margin-left: 15px;
                        // min-width: 100px;
                        border-color: $red-color;

                        .dx-button-text {
                            font-weight: $font-weight-700;
                            text-transform: none;
                        }

                        @media screen and (max-width: 959px) {
                            margin: 10px 0px;
                            width: 100px;
                        }
                    }
                }



                .select-theme {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    @media screen and (max-width: 959px) {
                        width: 98%;
                    }
                }

                .sign-out {
                    margin: 0px 0px 0px 12px;

                    @media screen and (max-width: 959px) {
                        align-self: flex-end;
                        order: 2;
                        max-width: max-content;
                    }

                    svg{
                        @media screen and (min-width : 2000px) {
                            width: 35px;
                            height: 35px;
                        }
                    }
                }
            }
        }
    }

}


// .header-title-table {
//     text-align: left;
//     margin: 0px 80px;

//     @media screen and (max-width: 768px) {
//         margin: 0px 15px;
//     }

//     @media screen and (max-width: 480px) {
//         margin: 0px 5px;
//         // margin: 0px 10px;
//         width: 100%;
//     }

//     .sub-header-title {
//         display: flex;
//         align-items: center;
//     }

//     .title {
//         font-size: 32px;
//         // color: $charcoal-color;
//         font-weight: bold;
//         // width: 84%;

//         @media screen and (max-width: 767px) and (max-width: 480px) {
//             font-size: 23px;
//         }
//     }

//     .search-input {
//         .dx-texteditor.dx-editor-outlined {

//             @media screen and (min-width: 1200px) {
//                 width: 430px;
//             }

//             @media screen and (max-width: 768px) {
//                 width: 95.7%;
//             }

//             @media screen and (max-width: 767px) and (max-width: 480px) {
//                 width: 96%;
//                 height: 47px;
//             }

//         }
//     }
// }

// .landingCard {
//     display: flex;
//     margin: 10px 80px;
//     // width: 100%;

//     @media screen and (max-width: 768px) {
//         margin: 10px 15px;
//     }

//     @media screen and (max-width: 480px) {
//         margin: 10px 10px;
//         // margin: 10px 0 0 10px;
//     }


//     // justify-content: space-between;
//     .grid {
//         width: 100%;

//         .grid-spacing {
//             display: flex;
//             // flex-wrap: wrap;
//             box-sizing: border-box;
//             width: calc(100% + 8px);

//             @media screen and (max-width: 959px) {
//                 flex-direction: column;
//             }
//         }
//     }
// }

// .landingCard1 {
//     margin: 10px 80px;
//     margin-bottom: 80px;
//     display: flex;
//     // width: 100%;


//     @media screen and (max-width: 768px) {
//         margin: 10px 15px;
//     }

//     @media screen and (max-width: 480px) {
//         margin: 10px 4px;
//         // margin: 10px 0 0 10px;
//     }

//     .grid1 {
//         width: 100%;

//         .grid-spacing1 {
//             display: flex;
//             // flex-wrap: wrap;
//             box-sizing: border-box;
//             width: calc(100% + 8px);

//             @media screen and (max-width: 959px) {
//                 flex-direction: column;
//             }
//         }
//     }
// }