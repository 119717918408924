@import "../../../../assets/themes/default/share.scss";
@import "../../../../assets/sass/global-import";

:root {
  --font-text-size: 12px;
}

.MuiCardHeader-root {
  align-items: end !important;

  .MuiCardHeader-content {
    .MuiCardHeader-title {
      color: var(--font-color) !important;
      font-size: var(--font-text-size) !important;
      font-weight: 600;
    }

    &.MuiTypography-colorTextSecondary {
      font-size: var(--font-text-size) !important;
    }

    .MuiTypography-body2 {

      font-size: var(--font-text-size) !important;
      font-weight: 600;
      word-spacing: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 245px;

      strong {
        font-size: 10px;
        color: #4a4a4af0;

        span {
          color: #777474;
          margin: 0px 3px;
        }
      }

      hr {
        border: none;
        margin: 2px 0px;
      }
    }
  }

  .MuiCardHeader-action {
    svg.MuiSvgIcon-root {
      color: $primary;
      font-size: 14px;
    }
  }
}

.Innerdetails {
  .infinite-scroll {
    margin-top: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 330px;
    min-height: 330px;
    @include custom-scroller("y");
  }

  .MuiGrid-spacing-xs-3 {
    padding: 5px 1px !important;

    .MuiPaper-elevation1 {
      box-shadow: 2px 3px 10px 1px rgba(0, 0, 0, 0.3);

      &:hover {
        box-shadow: 0px 0px 1px 1px $primary;
      }
    }
  }

  button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit {
    padding: 0px;
  }
}

.role-page {
  .MuiPaper-rounded {
    top: 51% !important;
    left: 18% !important;
    margin-top: 0px !important;

    @media screen and (max-width: 767px) {
      margin-top: 10px !important;
    }

    .MuiCardHeader-root {
      display: flex;
      padding: 5px 10px;

      .MuiCardHeader-avatar {
        margin-right: 7px;
        margin-top: 6px;

        .MuiAvatar-colorDefault {
          color: #fafafa;
          background-color: $primary;

          font-size: var(--font-text-size);
          text-transform: capitalize;
          width: 40px;
          height: 40px;
          font-weight: 600;
        }
      }
    }
  }
}

.MuiListItemIcon-root {
  min-width: 30px !important;

  svg.MuiSvgIcon-root {
    width: 17px;
    color: var(--font-color) !important;
    margin: 0px;
    padding: 0px;
  }
}

.MuiDialog-paperWidthSm .MuiDialogTitle-root {
  padding-left: 13px !important;

  .MuiTypography-h6 {
    margin: 7px;
    font-size: 13px;
  }
}

.MuiListItemText-root {
  .MuiTypography-body1 {
    font-size: var(--font-text-size) !important;
    font-weight: 500;
    // color: var(--url-color) !important;
  }

  .MuiPaper-elevation8 {
    box-shadow: 2px 1px 7px 1px rgba(158, 153, 153, 0.26) !important;
  }

  .profile-menu svg.MuiSvgIcon-root {
    font-size: 18px;
    margin-right: 10px;
  }
}

.MuiGrid-spacing-xs-3 {
  &.MuiGrid-item {
    @media screen and (max-width: 767px) {
      padding: 0px !important;
    }
  }
}

.addresses {
  @media screen and (max-width: 767px) {
    width: 42px !important;
  }
}

.dashboard-cover {
  @media screen and (max-width: 767px) {
    margin-left: 0% !important;
  }

  @media screen and (max-width: 1024px) and (min-width: 768px) {
    margin-left: 0% !important;
  }

  @media screen and (max-width: 1300px) and (min-width: 1024px) {
    margin-left: 0% !important;
  }

  width: 100% !important;
  margin-top: 0% !important;
}

.dynamic-table {
  @media screen and (max-width: 480px) {
    max-height: calc(79vh - 42px);
  }
}

.mainview {
  margin: 0px;
  margin-bottom: 0px;
  overflow: hidden;

  .maincontent {
    // background: $secondary;
    background-color: unset;
    display: flex;

    .dashboardcover {
      width: calc(100% - 0px);
      // background: var(--background-color-default);
      background-color: unset;

      &.hidesidebar {
        width: calc(100% - 40px);

        @media screen and (max-width: 767px) {
          width: 100%;
          margin-left: 1%;
          margin-top: 14%;
        }

        @media screen and (max-width: 1024px) and (min-width: 768px) {
          width: 100%;
          margin-left: 2%;
          margin-top: 6%;
        }

        @media screen and (max-width: 1300px) and (min-width: 1024px) {
          width: 100%;
          margin-left: 2%;
          margin-top: 5%;
        }
      }
    }
  }
}

.role-icon {
  display: flex;

  // justify-content: end;
  // justify-content: space-evenly;

  svg {
    cursor: pointer;
    border-radius: 3px;

    @media screen and (max-width : 550px) {
      width: 17px;
      height: 17px;
    }
  }

  .edit {
    margin-right: 4px;
  }

  .user-role {
    margin-right: 4px;
  }

  .MuiSvgIcon-root {
    color: white !important;
    padding: 1px 1px;
    font-size: 15px;
    margin: 0px 3px;
    background-color: $primary;
    border-radius: 4px;

    &:hover {
      color: $secondary;
    }
  }
}


.add-wrapper {

  .dx-overlay-content {
    width: 480px;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.13);
    // border: solid 1px #959596;

    @media screen and (max-width : 550px) {
      width: 98% !important;
      height: auto !important;
      transform: translate(4px, 10px) !important;
    }


    .dx-popup-bottom {
      .dx-toolbar-items-container {
        [aria-label="Cancel"] {
          background-color: #707070;
          border: none;
          color: #fff;
          margin-top: -27px;
        }

        [aria-label="Save"] {
          background-color: #0078d4;
          margin-top: -26px;
          margin-right: 23px;

          .dx-button-text {
            color: #fff;
          }
        }
      }
    }

    .dx-popup-title.dx-toolbar {
      padding: 8px 8px 0 30px;
      border-bottom: none;
    }

    .dx-toolbar-label>div {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.3px;
      color: #0078d4;
    }

    .dx-toolbar-after {
      display: none;
    }

    .dx-popup-content {
      padding: 3px 30px 28px 30px;
    }

    .dx-field {
      display: flex;
      flex-direction: column;

      .dx-field-label {
        align-self: flex-start;

        strong,
        span {
          font-size: 14px !important;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.07;
          letter-spacing: 0.23px;
          // color: #222;
        }
      }

      .dx-field-value {
        width: 100%;
        margin-top: 5px;

        .dx-textbox {
          height: 36px;
        }

        .dx-texteditor-container {
          align-items: center;

          .dx-texteditor-input-container{
            flex-wrap: nowrap;

            @media screen and (max-width : 500px) {
              overflow-x: scroll;
            }

          .dx-tag {
            align-self: center;
          }
        }
          .dx-tag-content {
            background-color: #0078d4;
            color: #fff;
            font-size: 12px;
            height: 22px;
            display: flex;
            align-items: center;

            .dx-tag-remove-button::before {
              top: 54%;
              left: 54%;
              width: 14px;
              height: 14px;
              line-height: 15px;
              background-color: #fff;
              color: #0078d4;
            }
          }
        }
      }
    }

    .MuiGrid-root {
      .MuiGrid-align-items-xs-center {
        margin-top: 12px;

        @media screen and (max-width : 550px) {
          margin-top: -18px
        }
      }
    }

    .add-lable {
      margin-top: -4px;
      margin-bottom: -12px;
    }

    .MuiGrid-spacing-xs-6>.MuiGrid-item {
      padding: 6px 25px;

      span {
        color: var(--font-color-default);
        font-size: var(--font-text-size);
      }
    }

    .MuiPaper {
      background: var(--background-color-default);
      color: var(--font-color);
      border: 1px solid var(--border-color-default);
      height: 154px;
      overflow-y: auto;
      width: 99%;
      margin-bottom: 7px;
    }

    .btnclass {
      margin-left: -28%;
      margin-top: 10%;
      text-transform: none !important;
      width: 128px;

      @media screen and (min-width : 960px) {
        margin-left: -144%;
        margin-top: 33%;
        text-transform: none !important;
        width: 107px;
      }

      @media screen and (max-width : 550px) {
        margin-left: 0%;
        margin-top: 4%;
      }
    }

    .left {
      border-color: #0078d4;

      span {
        color: #0078d4 !important;
        ;
      }
    }

    .right {
      border-color: #f55;

      span {
        color: #f55 !important;
        ;
      }
    }

    .search-bar {
      height: 36px;
    }

    .MuiPaper-root {
      background: var(--background-color-default);
      color: var(--font-color);
      border: solid 1px #979797;
      box-shadow: none;
      border-radius: 3px;

      .MuiSvgIcon-root {
        fill: #878b9f;
      }
    }

    // .delete-btn {
    //   float: right;

    //   .btn-role {
    //     margin-right: 15px;
    //   }
    // }

    // .save-btn {
    //   float: right;
    //   margin-top: 20px;

    //   .dx-button {
    //     &.dx-button-default {
    //       background-color: #0078D4;

    //       &.dx-state-disabled {
    //         background-color: #044c92b3;
    //         opacity: 0.5;

    //         .dx-button-text {
    //           color: #fff;
    //           font-weight: 800;
    //           font-size: var(--font-text-size);
    //         }
    //       }

    //       .dx-button-text {
    //         color: #fff;
    //         font-weight: 800;
    //         font-size: var(--font-text-size);
    //       }
    //     }
    //   }
    // }

    // }
  }
}

.maintain-users-in-role {

  .dx-overlay-content {
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.13);


    .MuiButton-sizeSmall {
      &:hover {
        background: unset !important;
      }
    }
    .dx-popup-content {
      .dx-validationgroup {
        .dx-scrollview {
          @media screen and (max-width : 500px) {
            height: 530px;
          }
        }

        .dx-field {
          display: flex;
          flex-direction: column;

          .dx-field-label {
            align-self: flex-start;

            strong,
            span {
              font-size: 14px !important;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.07;
              letter-spacing: 0.23px;
              // color: #222;
            }
          }

          .dx-field-value {
            width: 100%;
            margin-top: 5px;

            .dx-textbox {
              height: 36px;
            }

            .dx-texteditor-container {
              align-items: center;

              .dx-tag {
                align-self: center;
              }

              .dx-tag-content {
                background-color: #0078d4;
                color: #fff;
                font-size: 12px;
                height: 22px;
                display: flex;
                align-items: center;

                .dx-tag-remove-button::before {
                  top: 54%;
                  left: 54%;
                  width: 14px;
                  height: 14px;
                  line-height: 15px;
                  background-color: #fff;
                  color: #0078d4;
                }
              }
            }
          }
        }

        .dx-field {
          margin: 0 0 15px;

          .dx-field-value {

            .search-bar {
              height: 36px;
            }

            .dx-textbox {
              height: 36px;
            }
          }
        }
      }
    }

    .dx-popup-bottom {
      .dx-toolbar-items-container {
        [aria-label="Cancel"] {
          display: none;
        }

        [aria-label="Save"] {
          background-color: #0078d4;
          // margin-top: -45px;
          margin-right: 16px;
        }
      }
    }


    .add-lable {
      display: flex;
      align-items: center;
      margin-top: -7px;
    }

    .left {
      border-color: #0078d4;

      span {
        color: #0078d4 !important;
        ;
      }
    }

    .btnclass {
      margin-left: -28%;
      margin-top: 10%;
      text-transform: none !important;
      width: 128px;

      @media screen and (min-width : 960px) {
        margin-left: -144%;
        margin-top: 33%;
        text-transform: none !important;
        width: 107px;
      }

      @media screen and (max-width : 550px) {
        margin-left: 0%;
        margin-top: 4%;
      }
    }

    .right {
      border-color: #f55;

      span {
        color: #f55 !important;
        ;
      }
    }

    .MuiPaper-root {
      background: var(--background-color-default);
      color: var(--font-color);
      // border: solid 1px #979797;
      border: solid 1px #97979754;
      box-shadow: none;
      border-radius: 3px;

      .MuiSvgIcon-root {
        fill: #878b9f;
      }
    }

    .MuiGrid-spacing-xs-6>.MuiGrid-item {
      padding: 6px 25px;

      span {
        color: var(--font-color-default);
        font-size: var(--font-text-size);
      }
    }

    .MuiPaper {
      background: var(--background-color-default);
      color: var(--font-color);
      border: 1px solid var(--border-color-default);
      height: 156px;
      overflow-y: auto;
      width: 100%;
      margin-bottom: 7px;
    }
  }
}

.edit-wrapper {

  .dx-overlay-content {
    width: 480px;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.13);

    @media screen and (max-width : 550px) {
      width: 98% !important;
      height: auto !important;
      transform: translate(4px, 10px) !important;
    }

    .dx-popup-content {
      .dx-validationgroup {
        .dx-field {
          display: flex;
          flex-direction: column;

          .dx-field-label {
            align-self: flex-start;

            strong,
            span {
              font-size: 14px !important;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.07;
              letter-spacing: 0.23px;
              // color: #222;
            }
          }

          .dx-field-value {
            width: 100%;
            margin-top: 5px;

            .dx-textbox {
              height: 36px;
            }

            .dx-texteditor-container {
              align-items: center;

              .dx-tag {
                align-self: center;
              }

              .dx-tag-content {
                background-color: #0078d4;
                color: #fff;
                font-size: 12px;
                height: 22px;
                display: flex;
                align-items: center;

                .dx-tag-remove-button::before {
                  top: 54%;
                  left: 54%;
                  width: 14px;
                  height: 14px;
                  line-height: 15px;
                  background-color: #fff;
                  color: #0078d4;
                }
              }
            }
          }
        }

        .dx-field {
          margin: 0 0 15px;

          .dx-field-value {

            .search-bar {
              height: 36px;
            }

            .dx-textbox {
              height: 36px;
            }

            .dx-texteditor-container {
              align-items: center;

              // @media screen and (max-width : 500px) {
              //   overflow-x: scroll;
              // }

              .dx-tag-container {
                flex-wrap: nowrap;
                // overflow-x: scroll;
              }

              .dx-tag {
                align-self: center;
              }

              .dx-tag-content {
                background-color: #0078d4;
                color: #fff;
                font-size: 12px;
                height: 22px;
                display: flex;
                align-items: center;

                .dx-tag-remove-button::before {
                  top: 54%;
                  left: 54%;
                  width: 14px;
                  height: 14px;
                  line-height: 15px;
                  background-color: #fff;
                  color: #0078d4;
                }
              }
            }
          }
        }
      }
    }

    .dx-popup-bottom {
      .dx-toolbar-items-container {
        [aria-label="Cancel"] {
          display: none;
        }

        [aria-label="Save"] {
          background-color: #0078d4;
          margin-top: -45px;
          margin-right: 16px;

          @media screen and (max-width : 500px) {
            margin-top: unset;
          }
        }
      }
    }
  }
}


.delete-wrapper {

  .dx-overlay-content {
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.13);

    @media screen and (max-width : 550px) {
      width: 98% !important;
      height: 187px !important;
      transform: translate(4px, 10px) !important;
    }

    .delete-btn {
      float: left;
      margin-top: 22px;

      .dx-button {
        &.dx-button-normal {
          background-color: #707070;
        }

        &.dx-button-default {
          background-color: #0078D4;
        }

        .dx-button-text {
          color: #fff;
          font-weight: bold;
          font-size: var(--font-text-size);
          margin: 4px 4px;
        }

      }

      .btn-role {
        margin-right: 10px;
      }

    }
  }
}

.view-wrapper {
  &.view-wrapper-role-details {
    position: relative;
    display: flex;
    padding-top: var(--content-padding);
    padding-bottom: var(--content-padding);
    background-color: var(--main-background-color) !important;

    @media screen and (max-width :550px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}