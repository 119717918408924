@import "../../../../assets/themes/default/share.scss";
@import "../../../../assets/sass/styles.scss";

:root {
    --font-text-size: 12px;
    --background-color: #fff;
    --color: #0078d4;
    --border_color: #ccc;
    --font-color: #000000;
}

.SubHeader {
    // background-color: var(--dark-background-color1);

    .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
        margin: 0px;
        height: 64px;
        font-size: 27px;
        font-weight: 700;
        // border-bottom: solid 1px var(--border_color);
        padding: 0 26px;
        justify-content: space-between;

        @media screen and (max-width: 767px) {
            // display: block;
            height: auto;
            padding: 10px 5px;
        }

        .sub-header-text {
            // font-size: 27px;
            // font-weight: 500;
            // color: var(--header-title-color);
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 0.75;
            letter-spacing: 0.33px;
            color: #0078d4;
            

            @media screen and (max-width: 767px) {
                font-size: 18px;
            }
        }

        .btn-primary {
            height: 35px;
            max-width: 100%;
            margin-right: 10px;
            border-radius: 4px;
            background-color: var(--btn_background_color);
            font-size: var(--font-text-size);
            color: white;
            text-transform: capitalize;
            cursor: pointer;

            @media screen and (max-width: 767px) {
                max-width: 60%;
                // margin-left: 1%;
                // margin-top: 3%;
            }

            .dx-button-content {
                padding: 0;
            }
        }

        .btn-primarys {
            margin-right: 10px;

            @media screen and (max-width: 767px) {
                max-width: 90%;
                margin-left: 1%;
            }
        }

        .compare {
            background-color: white !important;
            color: var(--btn_background_color) !important;
            border-color: var(--btn_background_color) !important;
            border: 1px solid;
        }

        .subheader-filter {
            display: flex;
            align-items: flex-end;
            height: 50%;

            @media screen and (max-width: 767px) {
                // display: block;
                width: 80%;
                align-items: center;
            }

            .search-bar {
                margin-right: 10px;
                font-weight: normal;
                background-color: var(--background-color-default);

                .MuiInputBase-root {
                    color: var(--font-color-default);
                    font-size: 13px;
                }
            }

            .MuiPaper-elevation1.MuiPaper-rounded {
                width: 33%;
                height: 35px;

                @media screen and (max-width: 767px) {
                    width: 72%;
                }
            }

            .file-section {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .file-name {
                    margin-right: 10px;
                    font-size: var(--font-text-size);
                    font-weight: 400;
                    line-height: 1.5;
                }

                .MuiButton-contained {
                    background-color: var(--btn_background_color);
                    color: $secondary;
                }

                .MuiButton-containedSizeLarge {
                    padding: 2px 5px;
                    font-size: 0.9375rem;

                    @media screen and (max-width: 767px) {
                        font-size: 11px !important;
                    }

                    .labelspan {
                        text-transform: capitalize;
                        border-right: 1px solid var(--border_color);
                        padding: 0px 5px;
                        font-size: var(--font-text-size);
                    }

                    & svg.MuiSvgIcon-root {
                        font-size: $icon_font_size;
                        margin-left: 5px;
                    }
                }
            }
        }
    }


    .user-filter {
        margin: 2px 25px;
        display: flex;
        align-items: center;
        height: 29px;

        @media screen and (max-width : 530px) {
            height: 54px;
        }

        @media screen and (max-width: 767px) {
            margin: 0px;
            width: 62%;
        }

        .statuslabel {
            margin-right: 7px;
            font-size: var(--font-text-size);
            font-weight: bold;
            margin-bottom: 3%;
        }

        .filter-ddl {
            .selectAllFilter {
                border-radius: 4px;
            }
        }

        select.form-control {
            color: var(--font-color);
            align-items: center;
            background-color: var(--background-color);
            cursor: default;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            position: relative;
            box-sizing: border-box;
            border-color: var(--border_color);
            border-radius: 5px;
            border-style: solid;
            border-width: 1px;
            transition: all 100ms ease 0s;
            outline: 0px !important;
            margin-top: -15%;
            height: 35px;
            padding: 0px 7px;
            font-size: var(--font-text-size);
        }
    }

    .ForwardRef-root-132 {
        height: 35px;
    }
}

.filter-ddl {
    .selectFontSize {
        border-radius: 5px;

        .dx-selectbox-container {
            width: 102px;
            height: 36px;
        }

        .dx-texteditor-input {
            font-size: var(--font-text-size);
            padding: 15px 6px 14px;
        }
    }
}


.selectAllFilter {
    .dx-texteditor-container {
        width: 87px;
        height: 37px;

        .dx-texteditor-input-container {
            input {
                padding: 9px 5px 8px 11px;
            }
        }
    }

    .dx-texteditor-input {
        font-size: var(--font-text-size);
    }
}