@import "../../../assets/themes/default/share.scss";
@import '../../shared/components/commonui/style/index.scss';

:root {
  --font-text-size: 12px;
}

$icon_font_size: 18px;

.dashboard-cover {
  background: var(--background-color);

}

.basic-multi-select {
  color: #000000;
}

.MuiPaper-root.paging-tab.MuiPaper-elevation1 {
  margin: 0px 13px;
}

.container.select__value-container--is-multi.css-1hwfws3 {
  font-size: 9px;
}

.basic-multi-select {
  font-size: 10px;
  height: 25px !important;
}

.envelope-filter {
  padding-top: 20px;
  padding-bottom: 10px;

  .filter-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .grid-item {
      width: 100%;
      margin-bottom: 15px;

      .common-multi-select{
        .basic-ui-select{
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
          border: 1px solid var(--select-box-border-color);
        }
      }

      .title {
        font-size: 14px;
        font-weight: bold;
      }

      @media (min-width: 600px) {
        width: calc(50% - 10px);
      }

      @media (min-width: 960px) {
        width: calc(33.3333% - 10px);
      }
    }

    .xs-2 {
      @media (min-width: 600px) {
        width: calc(10% - 10px);
      }
    }

    .xs-10 {
      @media (min-width: 600px) {
        width: calc(90% - 10px);
      }
    }

    .md-1 {
      @media (min-width: 960px) {
        width: calc(5% - 10px);

        &.spacing-sv {
          margin-left: 20px;
        }
      }
    }

    .md-4 {
      @media (min-width: 960px) {
        width: calc(28.3333% - 10px);
      }
    }

    .md-2 {
      @media (min-width: 960px) {
        width: calc(15% - 10px);
      }
    }

    .filter-section {
      margin-left: 20px;

      .dx-button {
        min-height: 35px;
        min-width: 100px;
        background-color: #0078d4;

        &.dx-button-default {
          box-shadow: none;
          box-sizing: border-box;
        }
      }
    }
  }

  .filter {
    label {
      text-align: right;
      font-size: var(--font-text-size);
      float: right;
      align-items: center;
      margin-top: 10px;
      font-weight: 600;
      color: var(--font-color);
    }

    button {
      margin-top: 3px;
      text-transform: capitalize;
    }

    span,
    svg {
      line-height: 20px;
      height: 20px;
      font-weight: 700;
    }

    .select__placeholder.css-1wa3eu0-placeholder {
      font-size: var(--font-text-size);
    }

    span.MuiButton-label {
      color: var(--font-color) !important;
      font-weight: 600;
    }
  }
}

.modalpopoup {
  table.statusinfo {
    width: 100%;
  }
}

.filter-section {
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: flex-end;
  }
}

.filter span,
.filter svg {
  font-size: 0.8125rem;
}

.MuiTableBody-root .action-icon .MuiSvgIcon-root {
  color: var(--font-color);
}

.MuiDialogTitle-root .MuiIconButton-root .MuiSvgIcon-root {
  color: var(--url-color);
}

.MuiButton-sizeSmall {
  &:hover {
    background: $primary !important;
  }
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  background: var(--background-color-default);
  // color: var(--font-color);
}

.action-eye {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  cursor: pointer;
}


.dx-overlay-content {
  .MuiTableRow-root {


    .MuiTableCell-root {
      position: relative;
      color: var(--font-color-default);
      border-bottom: none;

      button.MuiButtonBase-root.MuiIconButton-root {
        position: absolute;
        right: 0;
        top: -6px;

        @media screen and (max-width: 767px) {
          right: -10px;
        }

        svg.MuiSvgIcon-root {
          font-size: 16px;
          color: white;
          background-color: #0078d4;
          border-radius: 4px;
        }
      }
    }
  }


}

.envelope-list-popup {}

.envelope-list-encrypt-popup {
  .dx-field {
    
    max-height: calc(40vh - 40px);
    overflow-y: auto;

    .dx-field-label {
      width: 25%;
      align-self: self-start;
      color: var(--font-color-default);
    }

    .dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
      width: 75%;
      word-wrap: break-word;



      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        border-radius: 4px;
      }

    }
  }
}
