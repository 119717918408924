.table-cover {
    width: 100%;
    overflow-x: auto;
    position: relative;
}

.MuiTable-root {

    &.table {
        table-layout: fixed;

        .MuiTableHead-root {

            .MuiTableCell-head {
                background: #2b4d7c;
                color: white;
                padding: 5px 16px;
                border: 1px solid #ddd;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                position: sticky;
                top: 0px;

                .MuiTableSortLabel-root {
                    &:hover, &:active, &:focus, &:focus-within {
                        color: white;
                    }

                    .MuiSvgIcon-root {
                        opacity: 1;
                        color: white;
                    }
                }
            }
        }

        .MuiTableBody-root {
            .MuiTableRow-root {

                .MuiTableCell-body {
                    padding: 2px 5px;
                    font-size: $font-text-size;
                    border: 1px solid #ddd;
                    text-align: left;
                }

                &:nth-child(2n+1) {
                    .MuiTableCell-body {
                        background-color: #f5f5f5;
                    }
                }

                &:hover {
                    .MuiTableCell-body {
                        background-color: #d0d0d0;
                    }
                }
            }
        }

        &.table-responsive {
            .MuiTableHead-root {
                .MuiTableCell-head {
                    min-width: 150px;
                    width: 150px;
                    z-index: 10;
                }
            }
        }
    }
}
