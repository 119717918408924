.main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 15px;
    flex-wrap: wrap;
    row-gap: 10px;

    .main-title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        svg{
            cursor: pointer;
        }
        .title {
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 0.75;
            letter-spacing: 0.33px;
            color: #0078d4;

            @media screen and (max-width: 767px) {
                font-size: 14px;
                font-weight: 600;
                line-height: 1.2;
            }
        }
    }

    .subheader-filter {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        row-gap: 10px;

        .user-filter {
            display: flex;
            align-items: center;

            @media screen and (max-width: 767px) {
                margin-top: 8px;
                // margin-bottom: 8px;
            }

            .statuslabel {
                font-size: 14px;
                // color: #000;
                font-weight: bold;
                margin-right: 10px;
            }
        }

        .search-header {
            margin: 0px 10px;

            // @media screen and (max-width: 480px) {
            //     margin-bottom: 8px;
            // }

            @media screen and (max-width : 700px ) {
                margin: 0 10px 0 3px;
            }
            &.dx-texteditor {
                &.dx-editor-outlined {
                    .dx-texteditor-input {
                        padding: 0px 16px;
                    }

                    .dx-placeholder {
                        &::before {
                            padding: 8px 16px;
                        }

                        &::after {
                            padding: 8px 16px;
                        }
                    }
                }
            }
        }

        .compare {
            background-color: white !important;
            color: var(--btn_background_color) !important;
            border-color: var(--btn_background_color) !important;
            border: 1px solid;
        }
    }
}