.social-card {
    border-radius: 0.625rem;
    box-shadow: var(--card-shadow);
    background-color: var(--background-color-default);
    border: none;
    padding: 1.875rem;
    width: 100%;
    overflow: hidden;

    @media screen and (max-width : 768px) {
        width: unset;
        margin-bottom: 10px
    }

    @media screen and (min-width : 768px) and (max-width: 950px) {
        gap: 20px;
        width: 85%;
    }

    @media only screen and (min-width: 1441px) {
        width: calc(20% - 31px);
    }

    @media screen and (max-width : 1440px) and (min-width : 1024px) {
        width: calc(20% - 34px);
    }

    .title {
        font-size: 1.5em;
        margin-bottom: 29px;
        color: var(--font-color-default);
        font-weight: 500;

        @media screen and (min-width : 2000px) {
            font-size: 2.5em;
        }
    }

    .inner {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .inner-wrapper {
            margin-bottom: 19px;
            margin-right: 20px;

            svg {
                @media screen and (min-width : 2000px) {
                    width: 85px;
                    height: 85px;
                }
            }
        }
    }
}